import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { RawLayer, selectLoadablesFromLayer } from './layers';

import { init } from './class';
import { RootState } from 'store/store';
import { create as createLayer } from './layers';
import { Loadable } from './items';

export type RawStage = Omit<Stage, "layerIds"> & { layers: RawLayer[] }
export type Stage = {
  id: string
  width: number
  height: number
  layerIds: string[]
  style?: React.CSSProperties
}

type StageState = {
  stageById: {[key: string]: Stage}
}

const initialState: StageState = {
  stageById:{}
};

export const stageSlice = createSlice({
  name: 'stage',
  initialState,
  reducers: {
    create: (state, { payload }: PayloadAction<RawStage>) => {
      const { layers, ...props } = payload;
      state.stageById[payload.id] = { ...props, layerIds: layers.map(layer => layer.id) }
    },
    createWhiteboard: (state, { payload }: PayloadAction<RawStage>) => {
      const { layers, ...props } = payload;
      state.stageById[payload.id] = { ...props, layerIds: layers.map(layer => layer.id) }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(init, ( state, { payload }) => {
        state.stageById = payload.stages.reduce((res, cur) => ({...res, [cur.id]: { ...cur, layerIds: cur.layers.map(layer => layer.id), layers: undefined }}), {})
      })
      .addCase(createLayer, ( state, { payload }) => {
        const { stageId, layerId } = payload;
        state.stageById[stageId].layerIds.push(layerId)
        return state
      })
  },
});

export const selectStage = (id: string) => ({ stages }: RootState) => {
  return stages.stageById[id];
}

export const selectLoadablesFromStage = (id: string) => createSelector(
  [
    (state: RootState) => state, 
    (state: RootState) => state.stages.stageById[id].layerIds
  ], 
  (state: RootState, layerIds: string[]): Loadable[] => {
    return layerIds.map(layerId => selectLoadablesFromLayer(state, layerId)).flat()
  }
) // TODO still inefficient, remove state from input selector somehow

export const selectActiveStage = (state: RootState) => {
  if(!state.class.activeStageId) {
    return null
  } 
  return state.stages.stageById[state.class.activeStageId];
}

export const { create, createWhiteboard } = stageSlice.actions;

export default stageSlice.reducer;
