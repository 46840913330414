import { JitsiMeeting, JaaSMeeting } from "@jitsi/react-sdk";
import IJitsiMeetExternalApi from "@jitsi/react-sdk/lib/types/IJitsiMeetExternalApi";
//import { IJitsiMeetExternalApi } from "@jitsi/react-sdk/lib/types";
import { Button, TextField, Fade } from "@mui/material";
import { useRef, forwardRef, useEffect, useState } from "react";
import { useJwtTokenQuery } from "rtk/api";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import ToolButton from "../../routes/app/Class/components/ToolButton";
import { Fa } from "../../styled/muiComponents";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import { Tool } from "routes/app/Class/stageview/components/Toolbar";
import { View, selectClass, setStudentTool } from "store/reducers/class/class";
import { useAppDispatch, useAppSelector } from "store/hooks";
import Typography from "@mui/material/Typography";

const MediaContainerGrid = styled(Grid)(({ theme }) => ({
	display: "flex",
	overflow: "hidden",
	width: "100%",
	height: "100%",
	paddingLeft: "15px",
	paddingRight: "15px",
}));
const LinkInputGrid = styled(Grid)(({ theme }) => ({
    width: "100%",
    overflow: "hidden",
    marginBottom: "12px",
    height: "42px"
}));
const LinkInputContentGrid = styled(Grid)(({ theme }) => ({
    display: "flex",
    padding: "12px",
    height: "100%",
    boxSizing: "border-box",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderRadius: "8px",
}));
const URLTextField = styled(TextField)(({ theme }) => ({
    width: "calc(100% - 64px)",
    "& .MuiOutlinedInput-root": {
        //color: theme.palette.white.main,    
    },    
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
    }
}));
type Props = {
    type: any
    teacher: boolean
}

const MediaShare = forwardRef(({ type, teacher }: Props, ref) => {
    const { t } = useTranslation();
    const API = useRef<IJitsiMeetExternalApi>(null);
    const { data, error, isLoading } = useJwtTokenQuery();
    const dispatch = useAppDispatch();
    const { roomId } = useAppSelector(selectClass);
    const [url, setUrl] = useState<null | string>(null)
    const [mediaStart, setMediaStart] = useState(false);

    const handleSubmit = (event: any) => {
        setMediaStart(true)
        event.preventDefault()
        setUrl(event.target[0].value)
        API.current?.executeCommand('toggleFilmStrip')
        API.current?.executeCommand(
            "startShareVideo",
            event.target[0].value
        );
    }

    if(API.current){
        console.log('toggle pls')
        API.current.executeCommand('toggleFilmStrip')
    }

    if (isLoading || !data) return <div>loading...</div>;
    if (!roomId) return <div>no room id</div>;

    return (
        <Grid
            item
            container
            direction="column"
            justifyItems="center"
            justifyContent="center"
            style={{ height: "100%", padding: 0, margin: 0 }}
        >
            {teacher && (
				<Fade
					in={!mediaStart}
					unmountOnExit={true}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							width: "80%",
							alignItems: "center",
							position: "absolute",
						}}
					>
						<img
							src="/assets/class-guru.svg"
							alt="Classuru"
							style={{ height: "100px" }}
						/>
						<Typography
							textAlign="center"
							variant="h1"
							color="#ffffff"
							mt={2}
							mb={4}
						>
							{t("Paste the Youtube URL to watch videos together.")}
						</Typography>
                        
                        <form onSubmit={handleSubmit} style={{height: "80px", width: "100%", maxWidth: "600px"}}>
                            <LinkInputContentGrid wrap="nowrap" alignItems="center">
                                <TextField variant="outlined" color="secondary" fullWidth placeholder="https://www.youtube..." InputProps={{ sx: { borderBottomRightRadius: 0, borderTopRightRadius: 0 } }}/>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    type="submit"
                                    color="secondary"
                                    style={{height: "100%", maxHeight: "unset", borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                >
                                    <Fa icon={["fas", "play"]} size="xl" style={{}}/>
                                </Button>
                            </LinkInputContentGrid>
                        </form>
					</div>
				</Fade>
			)}
            {/*ToDo condition for student and teacher*/}

            <Fade in={mediaStart}>
                <MediaContainerGrid
                    container
                    item
                    direction="column"
                    wrap="nowrap"
                    justifyItems="center"
                    justifyContent="center"
                    id="container"
                >
                    {teacher &&
                        <LinkInputGrid>
                            <form onSubmit={handleSubmit} style={{height: "40px", width: "100%"}}>
                                <URLTextField size="small" variant="outlined" color="secondary" placeholder="https://www.youtube..." InputProps={{ sx: { borderBottomRightRadius: 0, borderTopRightRadius: 0, backgroundColor: "#ffffff" } }}/>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    type="submit"
                                    color="secondary"
                                    style={{height: "100%", maxHeight: "unset", borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                >
                                    <Fa icon={["fas", "play"]} size="xl" style={{}}/>
                                </Button>
                            </form>
                        </LinkInputGrid>
                    }
                    <div
                        style={{
                            borderRadius: "5px",
                            height: "100%",
                            overflow: "hidden",
                        }}
                    >
                        <JaaSMeeting
                            appId={"vpaas-magic-cookie-c4914d37f4ae4551942881ba2a88d601"} // TODO ENV
                            // TODO GENERATE
                            jwt={data.token}
                            roomName={`${roomId}-test`}
                            interfaceConfigOverwrite={{
                                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                DISPLAY_WELCOME_FOOTER: false,
                                MOBILE_APP_PROMO: false,
                                SETTINGS_SECTIONS: [],
                                VIDEO_QUALITY_LABEL_DISABLED: true,
                                SHOW_CHROME_EXTENSION_BANNER: false,
                            }}
                            configOverwrite={{
                                startWithAudioMuted: true,
                                disableModeratorIndicator: true,
                                startWithVideoMuted: true,
                                //startScreenSharing: true,
                                enableEmailInStats: false,
                                prejoinPageEnabled: false,
                                toolbarButtons: [],
                                hideConferenceTimer: true,
                                hideConferenceSubject: true,
                                filmStrip: {
                                    disableStageFilmstrip: true,
                                    disableResizable: true
                                },
                                // filmStrip: false,


                                //disableResizable: true,
                                //disableStageFilmstrip: true,
                                participantsPane: {
                                hideModeratorSettingsTab: true,
                                hideMoreActionsButton: true,
                                hideMuteAllButton: true,
                                }
                            }}
                            getIFrameRef={(camRef: any) => {
                                camRef.style.width = "100%";
                                camRef.style.height='100%'
                                
                            }}
                            onApiReady={(externalApi: any) => {
                                //@ts-ignore TODO fix whatever it says
                                API.current = externalApi
                                console.log("should toggle")
                                externalApi.executeCommand('toggleFilmStrip')
                            }}
                        />
                    </div>
                </MediaContainerGrid>
            </Fade>
        </Grid>
    );
});

export default MediaShare


