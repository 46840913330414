import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "components/dialogs/DialogTitle.jsx";
import DialogContent from "components/dialogs/DialogContent.jsx";
import DialogActions from "components/dialogs/DialogActions.jsx";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import { Typography } from "@mui/material";
import { Lesson } from "rtk/types";

function PaperComponent(props: PaperProps) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper
				elevation={0}
				{...props}
				style={{ outline: "10px solid rgba(255,255,255,0.25)" }}
			/>
		</Draggable>
	);
}

interface Props {
	onClose: () => void;
	open: boolean;
	lesson?: Lesson;
}

const ViewCalendarEntryDialog: React.FC<Props> = ({
	onClose,
	open,
	lesson,
}) => {
	const handleEnterClassroom = () => {
		console.warn("not implemented");
		if (lesson) {
			window.open(`/classroom/${lesson.classroom}`, "_blank");
		}
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<DialogTitle id="draggable-dialog-title" onClose={onClose}>
				{lesson?.name}
			</DialogTitle>
			<DialogContent>
				<Grid container direction="row">
					<Grid item md={2}>
						<Avatar
							sx={{
								width: 70,
								height: 70,
								backgroundColor: "#2B2240",
							}}
						>
							JB
						</Avatar>
					</Grid>
					<Grid container item md={10}>
						<Grid item>
							<Typography variant="h2">
								{lesson
									? new Date(
											lesson.startAt
									  ).toLocaleDateString()
									: null}
							</Typography>
							<Typography style={{ fontSize: "2em" }}>
								{lesson
									? new Date(
											lesson.startAt
									  ).toLocaleTimeString() +
									  " - " +
									  new Date(
											lesson.endAt
									  ).toLocaleTimeString()
									: null}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid container direction="column">
					<Grid item>
						<p>{lesson?.description}</p>
					</Grid>
					<Grid item>
						<Typography variant="h2">
							StudentId:{" "}
							{lesson?.studentId
								? lesson.studentId
								: "not booked"}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h2">
							SlideCollectionId:{" "}
							{lesson?.slideCollectionId
								? lesson.slideCollectionId
								: "none selected"}
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h2">
							Visibility:{" "}
							{lesson?.visibility ? lesson.visibility : "not set"}
						</Typography>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleEnterClassroom}
					variant="contained"
					disableElevation
					color="success"
				>
					Enter Classroom
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ViewCalendarEntryDialog;
