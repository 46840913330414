import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { RawStage, create as createStage, createWhiteboard } from "./stages";
import { Tool } from "routes/app/Class/stageview/components/Toolbar";

export type RawClass = {
	stages: RawStage[];
	teacher: { id: number } | null;
	student: { id: number } | null;
};

type RoomUser = {
	id: string;
	username: string;
};

export enum View {
	Whiteboard = "whiteboard",
	Presentation = "presentation",
	Video = "video",
	WebCam = "webcam",
	ScreenSharing = "screenSharing",
	Background = "background",
}

type ClassState = {
	stageIds: string[];
	activeStageId: string | null;
	whiteboardIds: string[];
	activeWhiteboardId: string | null;
	stars: number;
	connectionState: "none" | "connecting" | "connected" | "disconnected";
	roomConnectionState: "none" | "connecting" | "connected" | "disconnected";
	roomId: string | null;
	wsClientId: string | null;
	clientsInRoom: RoomUser[];
	teacher: { id: number } | null;
	student: { id: number } | null;
	timer: {
		isRunning: boolean;
		time: number;
	};
	scale: number;
	view: View;
	studentTool: Tool | null;
	teacherTool: Tool;
};

const initialState: ClassState = {
	stageIds: [],
	activeStageId: null,
	whiteboardIds: [],
	activeWhiteboardId: null,
	stars: 0,
	connectionState: "none",
	roomConnectionState: "none",
	roomId: null,
	wsClientId: null,
	clientsInRoom: [],
	teacher: null,
	student: null,
	timer: {
		isRunning: false,
		time: 0,
	},
	scale: 1,
	view: View.Presentation,
	studentTool: Tool.Pointer,
	teacherTool: Tool.Pointer,
};

export const classSlice = createSlice({
	name: "class",
	initialState,
	reducers: {
		init: (state, { payload: rawState }: PayloadAction<RawClass>) => {
			if (rawState.stages.length > 0) {
				state.activeStageId = rawState.stages[0].id;
				state.stageIds = rawState.stages.map((stage) => stage.id);
			} else {
				state.activeStageId = null;
			}
			state.teacher = rawState.teacher;
			state.student = rawState.student;

			// state.teacher = {
			// 	...rawState.teacher,
			// };
			// state.student = {
			// 	...rawState.student,
			// };
		},
		setStars: (state, { payload }: PayloadAction<number>) => {
			if (payload > state.stars) {
				new Audio("/assets/audio/star.mp3").play();
			}
			state.stars = payload;
		},
		nextStage: (state) => {
			if (!state.activeStageId)
				throw new Error("Cannot go to next stage, no active stage");
			if (
				state.stageIds[state.stageIds.length - 1] ===
				state.activeStageId
			)
				throw new Error(
					"Cannot go to next stage, already at last stage"
				);
			state.activeStageId =
				state.stageIds[
					state.stageIds.indexOf(state.activeStageId) + 1
				] || null;
		},
		prevStage: (state) => {
			if (!state.activeStageId)
				throw new Error("Cannot go to next stage, no active stage");
			if (state.stageIds[0] === state.activeStageId)
				throw new Error(
					"Cannot go to previous stage, already at first stage"
				);
			state.activeStageId =
				state.stageIds[
					state.stageIds.indexOf(state.activeStageId) - 1
				] || null;
		},
		goToStage: (state, { payload }: PayloadAction<string>) => {
			state.activeStageId = payload;
		},
		goToWhiteboard: (state, { payload }: PayloadAction<string>) => {
			state.activeWhiteboardId = payload;
		},
		nextWhiteboard: (state) => {
			if (!state.activeWhiteboardId)
				throw new Error(
					"Cannot go to next whiteboard, no active whiteboard"
				);
			if (
				state.whiteboardIds[state.whiteboardIds.length - 1] ===
				state.activeWhiteboardId
			)
				throw new Error(
					"Cannot go to next whiteboard, already at last whiteboard"
				);
			state.activeWhiteboardId =
				state.whiteboardIds[
					state.whiteboardIds.indexOf(state.activeWhiteboardId) + 1
				] || null;
		},
		prevWhiteboard: (state) => {
			if (!state.activeWhiteboardId)
				throw new Error(
					"Cannot go to prev whiteboard, no active whiteboard"
				);
			if (state.whiteboardIds[0] === state.activeWhiteboardId)
				throw new Error(
					"Cannot go to prev whiteboard, already at first whiteboard"
				);
			state.activeWhiteboardId =
				state.whiteboardIds[
					state.whiteboardIds.indexOf(state.activeWhiteboardId) - 1
				] || null;
		},
		startConnecting: (state) => {
			state.connectionState = "connecting";
		},
		connected: (state, { payload }: PayloadAction<string>) => {
			state.connectionState = "connected";
			state.wsClientId = payload;
		},
		startJoiningRoom: (state, { payload }: PayloadAction<string>) => {
			state.roomConnectionState = "connecting";
			state.roomId = payload;
		},
		joinedRoom: (state, { payload }: PayloadAction<RoomUser>) => {
			state.roomConnectionState = "connected";
			state.clientsInRoom.push(payload);
		},
		setTimer: (state, { payload }: PayloadAction<number>) => {
			state.timer.time = payload;
		},
		startTimer: (state) => {
			state.timer.isRunning = true;
		},
		stopTimer: (state) => {
			state.timer.isRunning = false;
		},
		tick: (state) => {
			state.timer.time -= 1;
		},
		disconnected: (state, { payload: clientId }: PayloadAction<string>) => {
			if (clientId === state.wsClientId) {
				state.clientsInRoom = [];
				state.wsClientId = null;
				state.connectionState = "disconnected";
				state.roomConnectionState = "disconnected";
			} else {
				state.clientsInRoom = state.clientsInRoom.filter(
					(client) => client.id !== clientId
				);
			}
		},
		setScale: (state, { payload }: PayloadAction<number>) => {
			state.scale = payload;
		},
		changeView: (state, { payload }: PayloadAction<View>) => {
			state.view = payload;
		},
		setStudentTool: (state, { payload }: PayloadAction<Tool | null>) => {
			state.studentTool = payload;
		},
		setTeacherTool: (state, { payload }: PayloadAction<Tool>) => {
			state.teacherTool = payload;
		},

		setEqualState: (state, { payload }: PayloadAction<ClassState>) => {
			console.log(payload);
			Object.keys(state).map((key: any) => {
				if (key != "wsClientId") {
					//@ts-ignore
					state[key] = payload[key];
				}
			});
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createStage, (state, { payload }) => {
				state.stageIds.push(payload.id);
			})
			.addCase(createWhiteboard, (state, { payload }) => {
				state.whiteboardIds.push(payload.id);
			});
	},
});

export const {
	init,
	setStars,
	startConnecting,
	connected,
	startJoiningRoom,
	joinedRoom,
	disconnected,
	nextStage,
	prevStage,
	setTimer,
	startTimer,
	stopTimer,
	setScale,
	goToStage,
	changeView,
	nextWhiteboard,
	prevWhiteboard,
	goToWhiteboard,
	setStudentTool,
	setTeacherTool,
	setEqualState,
} = classSlice.actions;
export const selectClass = (state: RootState) => state.class;
export const selectIsConnected = createSelector(
	[(state: RootState) => state.class.connectionState],
	(connectionState) => connectionState === "connected"
);

export default classSlice.reducer;
