import { Button, Grid } from "@mui/material";
import { useValidation } from "components/form/validators";
import { TextField } from "mui-rff";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { useRegisterMutation } from "rtk/api";
import { Fa, Typography } from "../../styled/muiComponents";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { decode } from "util/hashids";
import {
	LoginWrapper,
	LoginContainer,
} from "routes/app/Class/stageview/constants/styledComponents";

const FormField = styled(TextField)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

const Register = () => {
	const [register, { isError, error, isSuccess, data }] =
		useRegisterMutation();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const { required } = useValidation(t);
	let err = error as any;

	const handleRegister = (values: any) => {
		register({
			email: values.email,
			username: values.username,
			lessonInviteToken: searchParams.get("t"),
		});
	};

	if (isSuccess && data && data.token) {
		return (
			<Navigate
				to={`/confirm_email/${data.token}?${searchParams.toString()}`}
			/>
		);
	}

	return (
		<LoginWrapper
			container
			justifyContent="center"
			alignItems="center"
			direction="column"
			wrap="nowrap"
		>
			<img
				src="/assets/class-guru.svg"
				alt="Classuru"
				style={{ height: "100px", marginBottom: "32px" }}
			></img>
			<Grid
				container
				item
				direction="row"
				justifyContent="center"
				alignItems="center"
			>
				<LoginContainer
					container
					item
					xs={12}
					sm={8}
					md={4}
					xl={3}
					direction={"column"}
					spacing={2}
				>
					<Grid
						container
						direction="column"
						item
						style={{ padding: 0 }}
					>
						<Grid item>
							<Typography variant="h1" mb={3}>
								Register
							</Typography>
						</Grid>
						<Grid item>
							{isError && err && (
								<Alert
									severity="error"
									style={{
										border: "1px solid red",
										marginBottom: "24px",
									}}
								>
									<AlertTitle>Error</AlertTitle>
									{err.data.error}
								</Alert>
							)}
							{isSuccess && (
								<Alert
									severity="success"
									style={{
										border: "1px solid #8eb32f",
										marginBottom: "24px",
									}}
								>
									<AlertTitle>Success</AlertTitle>
									An email to verify your data is already on
									its way to you!
								</Alert>
							)}
							<Form
								onSubmit={handleRegister}
								initialValues={{
									email: searchParams.get("e")
										? decode(
												searchParams.get("e") as string
										  )
										: null,
								}}
							>
								{({ handleSubmit }) => (
									<form onSubmit={handleSubmit}>
										<FormField
											name="username"
											label={t("Username")}
											fieldProps={{ validate: required }}
											required
										/>
										<FormField
											name="email"
											label={t("E-Mail")}
											type="email"
											fieldProps={{ validate: required }}
											disabled={!!searchParams.get("e")}
											required
										/>
										<Button
											color="primary"
											disableElevation
											variant="contained"
											type="submit"
											style={{
												padding: "14px",
												width: "100%",
											}}
										>
											<Fa
												icon={["far", "pen-to-square"]}
												fixedWidth
												style={{ marginRight: "8px" }}
											/>
											{t("register")}
										</Button>
									</form>
								)}
							</Form>
						</Grid>
					</Grid>
					<Grid item mt={2} style={{ padding: 0 }}>
						<Button
							component={Link}
							size="small"
							to={`/login?${searchParams.toString()}`}
						>
							{t("Login")}
						</Button>
					</Grid>
				</LoginContainer>
			</Grid>
		</LoginWrapper>
	);
};

export default Register;
