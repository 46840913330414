import {
	useState,
	useEffect,
	useRef,
	FormEvent,
	FocusEvent,
	useTransition,
} from "react";
import StageViewer, { StagePreview } from "./stageview/StageViewer";
import ImageSearch from "./components/ImageSearch/ImageSearch";
import Grid from "@mui/material/Grid";

import Fade from "@mui/material/Fade";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useAppSelector, useAppDispatch } from "store/hooks";

import { v4 as uuid } from "uuid";
import IJitsiMeetExternalApi from "@jitsi/react-sdk/lib/types/IJitsiMeetExternalApi";
import {
	selectClass,
	init,
	RawClass,
	setScale,
	goToStage,
	View,
	goToWhiteboard,
	setEqualState,
} from "store/reducers/class/class";

import { selectSession } from "store/reducers/session";

import { createWhiteboard } from "store/reducers/class/stages";

import CamsComponent from "components/videofeed/WebcamContainer";
import ScreenShare from "components/videofeed/ScreenShare";
import MediaShare from "components/videofeed/MediaShare";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
	ClassRoomContainer,
	MainContent,
	Scrollbar,
	SideBar,
	SlidesWrapper,
	Stages,
} from "./stageview/constants/styledComponents";
import { MediaToolbarButtons } from "./stageview/components/MediaToolbarButtons";
import { generateEmptyWhiteboard } from "./stageview/constants/generateEmptyWhiteboard";
import { NoSlideCollectionPlaceholder } from "./stageview/components/NoSlideCollectionPlaceHolder";
import { ClassroomButtons } from "./stageview/components/RoomButtons";
import { useTranslation } from "react-i18next";
import { setNavbarState } from "store/reducers/app/reducer";

{/* ToDo condition for student and teacher*/}
export enum Role {
	Student = "student",
	Teacher = "teacher",

	// Student = UserRole.Student,
	// Teacher = UserRole.Teacher,
}

interface Image {
	id: string;
	urls: {
		small: string;
		regular: string;
		full: string;
	};
}

export type ShowItemsState = {
	stages: boolean;
	background: boolean;
};

const ClassRoom = ({ rawClass }: { rawClass: RawClass }) => {
	const navigate = useNavigate();
	const classRoom = useAppSelector(selectClass);
	const dispatch = useAppDispatch();
	const [role, setRole] = useState<Role | null>(null);
	const session = useAppSelector(selectSession);
	const [backgroundImage, setBackgroundImage] = useState("");
	const [searchQuery, setSearchQuery] = useState("");
	const [images, setImages] = useState<Image[]>([]);
	const [page, setPage] = useState<number>(1);
	const [submittedSearchQuery, setSubmittedSearchQuery] = useState("");
	const [showItems, setShowItems] = useState<ShowItemsState>({
		stages: false,
		background: false,
	});
	const { t } = useTranslation();

	const jitsiAPIref = useRef<IJitsiMeetExternalApi | null>(null);

	//! wtf is this
	console.log(classRoom);

	useEffect(() => {
		if (
			classRoom.stageIds.length === 0 ||
			!classRoom.stageIds ||
			classRoom.clientsInRoom.length != 2
		) {
			return;
		}

		dispatch(setEqualState(classRoom));
	}, [classRoom.clientsInRoom.length]);

	useEffect(() => {
		dispatch(init(rawClass));
		dispatch(setNavbarState("closed"));
	}, []);

	useEffect(() => {
		searchImage(searchQuery, page);
	}, [page]);

	useEffect(() => {
		if (!classRoom) return;
		if (session.user?.id === classRoom?.teacher?.id) {
			setRole(Role.Teacher);
		} else if (session.user?.id === classRoom?.student?.id) {
			setRole(Role.Student);
		}
	}, [session, classRoom]);

	useEffect(() => {
		if (
			classRoom.view === View.Whiteboard &&
			classRoom.whiteboardIds.length === 0 &&
			role === Role.Teacher //! role
		) {
			const id = uuid();
			dispatch(createWhiteboard(generateEmptyWhiteboard(id)));
			dispatch(goToWhiteboard(id));
		}
	}, [classRoom.view]);

	const handleSubmitSearchField = (value: string) => {
		// const currentInput = textFieldRef.current!.value;
		if (classRoom.view === View.Background) {
			setSearchQuery(value);
			searchImage(value, page);
		} else {
			// textFieldRef.current?.value = "slideSearch here";
		}
	};

	const handleGoToStage = (stageId: string) => {
		dispatch(goToStage(stageId));
		setShowItems({ background: false, stages: false });
	};

	const handleZoomIn = () => {
		dispatch(setScale(classRoom.scale + 0.1));
	};

	const handleZoomOut = () => {
		dispatch(setScale(classRoom.scale - 0.1));
	};

	const handleZoomReset = () => {
		dispatch(setScale(1));
	};

	const searchImage = async (query: string, page: number) => {
		try {
			const response = await axios.get(
				"https://api.unsplash.com/search/photos",
				{
					params: {
						query: query,
						per_page: 10,
						orientation: "landscape",
						page: page,
					},
					headers: {
						Authorization: `Client-ID BzRWN_qZEhYWGyGn7wo5tbhTiYpLWGOGUKJddzZzjVI`,
					},
				}
			);

			if (response.data.results.length > 0) {
				setImages((prevImages) => [
					...prevImages,
					...response.data.results,
				]);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (submittedSearchQuery !== "") {
			searchImage(submittedSearchQuery, page);
		}
	}, [submittedSearchQuery, page]);

	//! maybe wichtig?
	const selectImage = (image: Image) => {
		setBackgroundImage(image.urls.regular);
	};

	const renderView = () => {
		switch (classRoom.view) {
			case View.Presentation:
				if (classRoom.activeStageId === null && role === Role.Teacher) {
					return <NoSlideCollectionPlaceholder />;
				} else if (
					// ToDo condition for student and teacher / teacher
					classRoom.activeStageId === null &&
					role === Role.Student
				) {
					return (
						<h1>
							{t(
								"Your teacher is setting up the classroom. Please have patience"
							)}
						</h1>
					);
				} else {
					return (
						// ! no imports needed => refactor
						<StageViewer
							stageId={classRoom.activeStageId}
							onZoomIn={handleZoomIn}
							onZoomOut={handleZoomOut}
							onZoomReset={handleZoomReset}
							scale={classRoom.scale}
							studentTool={classRoom.studentTool}
							teacherTool={classRoom.teacherTool}
							userRole={role}
						/>
					);
				}
			case View.Whiteboard:
				return (
					// ! same as above
					<StageViewer
						stageId={classRoom.activeWhiteboardId}
						onZoomIn={handleZoomIn}
						onZoomOut={handleZoomOut}
						onZoomReset={handleZoomReset}
						scale={classRoom.scale}
						studentTool={classRoom.studentTool}
						teacherTool={classRoom.teacherTool}
						userRole={role}
					/>
				);
			case View.Video:
				return (
					<MediaShare
						type={View.Video}
						ref={jitsiAPIref}
						teacher={role === Role.Teacher}
					/>
				);
			case View.ScreenSharing:
				return (
					<ScreenShare
						type={View.ScreenSharing}
						ref={jitsiAPIref}
						teacher={role === Role.Teacher}
					/>
				);
			case View.WebCam:
				return <></>;
			case View.Background:
				return (
					<ImageSearch
						setBackgroundImage={setBackgroundImage}
						images={images}
						showBackground={showItems.background}
						setPage={setPage}
					/>
				);
		}
		return <h1 style={{ color: "white" }}> Not implemented! </h1>;
	};

	return (
		<ClassRoomContainer
			container
			direction="column"
			wrap="nowrap"
			style={{
				backgroundImage: `url(${backgroundImage})`
			}}
		>
			<ClassroomButtons
				isTeacher={role === Role.Teacher}
				handleSubmitSearchField={handleSubmitSearchField}
				showItems={showItems}
				setShowItems={setShowItems}
			/>

			<ClassRoomContainer
				container
				direction="row"
				wrap="nowrap"
				style={{ height: "calc(100% - 120px)", backgroundColor: "transparent" }}
			>
				<MainContent
					container
					item
					direction="column"
					justifyContent="center"
					alignItems="center"
					wrap="nowrap"
					style={{ maxHeight: "100vh" }}
				>
					<Fade in={showItems.stages}>
						<SlidesWrapper container>
							<Scrollbar
								options={{
									wheelPropagation: true,
									suppressScrollX: true,
								}}
							>
								<Grid
									container
									columns={{ xs: 4, sm: 8, md: 12 }}
									wrap="wrap"
									gap="10px"
									direction="row"
									justifyItems="flex-start"
									alignItems="flex-start"
									alignContent="flex-start"
								>
									{classRoom.stageIds.map((id: string) => (
										<StagePreview
											key={id}
											stageId={id}
											onClick={handleGoToStage}
										/>
									))}
								</Grid>
							</Scrollbar>
						</SlidesWrapper>
					</Fade>
					<Stages
						container
						item
						justifyContent="center"
						alignItems="center"
					>
						{renderView()}
					</Stages>
				</MainContent>

				<SideBar container item direction="column" wrap="nowrap">
					<CamsComponent
						studentTool={classRoom.studentTool}
						showToolButtons={role === Role.Teacher}
						bigCam={classRoom.view === View.WebCam}
					/>
				</SideBar>
			</ClassRoomContainer>

			{role === Role.Teacher && (
				<MediaToolbarButtons /> // TODO setshowbackground
			)}
		</ClassRoomContainer>
	);
};

export default ClassRoom;
