import React from "react";
import { useAppSelector } from "store/hooks";
import {
	ItemType,
	selectItem,
	RawItem as IRawItem,
} from "store/reducers/class/items";
import Image, { ImageProps, RawImage } from "./Image";
import Rect, { RectProps } from "./Rect";
import Line, { LineProps } from "./Line";
import Ping, { PingProps } from "./Ping";

type Props = {
	itemId: string;
	scale?: { width: number; height: number };
};

const Item: React.FC<Props> = ({ itemId, scale }) => {
	const item = useAppSelector(selectItem(itemId));
	const { type, ...props } = item;

	switch (type) {
		case ItemType.Image:
			return <Image {...(props as ImageProps)} scale={scale} />;
		case ItemType.Rect:
			return <Rect {...(props as RectProps)} scale={scale} />;
		case ItemType.Line:
			return <Line {...(props as LineProps)} />;
		case ItemType.Ping:
			return <Ping {...(props as PingProps)} />;
		default:
			console.error("unknown Item type: ", type, item);
			return null;
	}
};

type RawProps = {
	item: IRawItem;
	scale?: { width: number; height: number };
};

export const RawItem: React.FC<RawProps> = ({ item, scale }) => {
	const { type, ...props } = item;

	switch (type) {
		case ItemType.Image:
			return <RawImage {...(props as ImageProps)} scale={scale} />;
		case ItemType.Rect:
			return <Rect {...(props as RectProps)} scale={scale} />;
		case ItemType.Line:
			return <Line {...(props as LineProps)} />; //TODO Scaling
		case ItemType.Ping:
			return <Ping {...(props as PingProps)} />; //TODO Scaling
		default:
			console.error("unknown Item type: ", type, item);
			return null;
	}
};

export default Item;
