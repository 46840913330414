import React, { useState } from "react";
import { useGetFolderQuery } from "rtk/api";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FolderChild, Folder as IFolder } from "rtk/types";
import { Fa } from "styled/muiComponents";
import { Button } from "styled/muiComponents";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const FolderGroup = styled("div")(({ theme }) => ({
	//borderLeft: `1px solid ${theme.palette.primary.main}`,
	listStyleType: "none",
	padding: 0,
	"@:hover": {
		backgroundColor: theme.palette.primary.main,
	},
}));
const StyledCardGrid = styled(Card)(({ theme }) => ({
	borderRadius: `${theme.spacing(2)}`,
}));
interface FolderProps {
	id: number;
	view: "nav" | "list" | "tiles" | "list_child" | "tiles_child"; // Updated prop name and type
	onClick: (folder: IFolder | FolderChild) => void;
	depth: number;
}

type ListEntryProps = {
	label: string;
	kind: FolderChild["kind"];
	onClick: () => void;
	style: any;
};

const ListEntry = ({ label, kind, onClick, style }: ListEntryProps) => {
	let icon;
	switch (kind) {
		case "FOLDER":
			icon = ["fa", "folder"];
			break;
		case "SLIDE_COLLECTION":
			icon = ["fa", "chalkboard"];
			break;
		default:
			console.error("Unpsupported kind", label, kind);
			return null;
	}

	return (
		<Button onClick={() => onClick()} variant="text" style={style}>
			<Fa icon={icon as any} />
			{label}
		</Button>
	);
};

function ExpandableListEntry({
	folder,
	onClick,
	depth,
}: {
	folder: IFolder;
	onClick: FolderProps["onClick"];
	depth: number;
}) {
	const [open, setOpen] = useState(false);
	const theme = useTheme();
	const handleToggle = () => {
		onClick(folder);
		setOpen(!open);
	};

	return (
		<FolderGroup>
			<List component="ul" disablePadding style={{ padding: 0 }}>
				<ListItemButton onClick={handleToggle}>
					<ListItemIcon
						style={{
							paddingLeft: 32 * depth,
							minWidth: 32,
							width: 32,
						}}
					>
						{open ? (
							<Fa icon={["far", "folder-open"]} />
						) : (
							<Fa icon={["far", "folder-closed"]} />
						)}
					</ListItemIcon>
					<ListItemText primary={folder.name + " [" + depth + "]"} />{" "}
					{/*open ? <ExpandLess /> : <ExpandMore />*/}
				</ListItemButton>
				<Collapse in={open}>
					{folder.children
						.filter((child) => child.kind === "FOLDER")
						.map((child) => (
							<Folder
								key={child.id}
								id={child.id}
								view="nav"
								onClick={onClick}
								depth={depth + 1}
							/>
						))}
				</Collapse>
			</List>
		</FolderGroup>
	);
}
const Folder: React.FC<FolderProps> = ({ id, view, onClick, depth }) => {
	const {
		data: folder,
		isLoading,
		isSuccess,
		isError,
	} = useGetFolderQuery({ id });

	if (isLoading)
		return (
			<div style={{ width: "100%", textAlign: "center" }}>Loading...</div>
		);
	if (isError)
		return (
			<div style={{ width: "100%", textAlign: "center" }}>Error...</div>
		);

	if (!folder) {
		return (
			<div style={{ width: "100%", textAlign: "center" }}>
				Folder not found
			</div>
		); // TODO should be error case, so probably not necessary
	}

	if (view === "nav") {
		switch (
			folder.children.filter((child) => child.kind === "FOLDER").length
		) {
			case 0:
				return (
					<List component="ul" disablePadding style={{ padding: 0 }}>
						<ListItemButton onClick={() => onClick(folder)}>
							<ListItemIcon
								style={{
									paddingLeft: 32 * depth,
									minWidth: 32,
									width: 32,
								}}
							>
								<Fa icon={["far", "folder-closed"]} />
							</ListItemIcon>
							<ListItemText
								primary={folder.name + " [" + depth + "]"}
							/>{" "}
							{/* EXAMPLE SERGEI depth*/}
						</ListItemButton>
					</List>
				);
			default:
				return (
					<ExpandableListEntry
						folder={folder}
						onClick={onClick}
						depth={depth}
					/>
				);
		}
	} else if (view === "list") {
		// Rendering variant for the main view
		return (
			<div key={`folder-${id}`}>
				<h1>
					{folder.name} [{depth}] {/* EXAMPLE FOR SERGEI depth*/}
				</h1>
				<List component="ul" disablePadding>
					{folder.children.map((child) => (
						<ListItemButton>
							{child.kind === "FOLDER" ? (
								<Folder
									key={"folder-" + child.id}
									id={child.id}
									onClick={onClick}
									view="list_child"
									depth={depth + 1}
								/>
							) : (
								<ListEntry
									key={child.kind + " " + child.id}
									kind={child.kind}
									label={child.name}
									onClick={() => onClick(child)}
									style={{}}
								/>
							)}
						</ListItemButton>
					))}
				</List>
			</div>
		);
	} else if (view === "list_child") {
		return (
			<ListEntry
				kind={"FOLDER"}
				label={folder.name}
				onClick={() => onClick(folder)}
				style={{}}
			/>
		);
	} else if (view === "tiles") {
		// Rendering variant for the main view
		return (
			<div key={`folder-${id}`}>
				<Grid container gap="16px" direction="row" wrap="wrap">
					{folder.children.map((child) => (
						<StyledCardGrid elevation={0} variant="outlined">
							{child.kind === "FOLDER" ? (
								<Folder
									key={"folder-" + child.id}
									id={child.id}
									onClick={onClick}
									view="tiles_child"
									depth={depth + 1}
								/>
							) : (
								<ListEntry
									key={child.kind + " " + child.id}
									kind={child.kind}
									label={child.name}
									onClick={() => onClick(child)}
									style={{}}
								/>
							)}
						</StyledCardGrid>
					))}
				</Grid>
			</div>
		);
	} else if (view === "tiles_child") {
		return (
			<ListEntry
				kind={"FOLDER"}
				label={folder.name}
				onClick={() => onClick(folder)}
				style={{
					padding: "16px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					"& span": {
						margin: "0px !important",
					},
				}}
			/>
		);
	} else {
		return null; // Handle an invalid view value (optional)
	}
};

export default Folder;
