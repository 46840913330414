import react from "react";
import {
	ClassRoomContainer,
	LoadingBubble,
} from "../constants/styledComponents";
import Grid from "@mui/material/Grid";
import { Typography } from "styled/muiComponents";
import Button from "@mui/material/Button";

export const NoSlideCollectionPlaceholder = () => {
	return (
		<ClassRoomContainer
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			style={{ textAlign: "center", color: "#ffffff" }}
			wrap="nowrap"
		>
			<img
				src="/assets/class-guru.svg"
				alt="Classuru"
				style={{ width: "100%", maxWidth: "100px" }}
			/>
			<Grid
				container
				item
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<LoadingBubble item>
					<Typography
						variant="h1"
						style={{ lineHeight: 1 }}
						mt={0}
						mb={1}
					>
						No materials selected
					</Typography>
					<Typography variant="body1">
						It looks like there are no slides configured to be used
						for this lesson.
					</Typography>
					<Typography variant="body1">Lets fix that!</Typography>
					<Grid container flexDirection="column" p={2} pb={0}>
						<Grid item>
							<Button color="primary" variant="contained">
								Choose Material
							</Button>
						</Grid>
					</Grid>
				</LoadingBubble>
			</Grid>
		</ClassRoomContainer>
	);
};
