import { JitsiMeeting, JaaSMeeting } from "@jitsi/react-sdk";
import IJitsiMeetExternalApi from "@jitsi/react-sdk/lib/types/IJitsiMeetExternalApi";
//import { IJitsiMeetExternalApi } from "@jitsi/react-sdk/lib/types";
import { Button } from "@mui/material";
import { useRef, forwardRef, useEffect, useState } from "react";
import { useJwtTokenQuery } from "rtk/api";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import ToolButton from "../../routes/app/Class/components/ToolButton";
import { Fa } from "../../styled/muiComponents";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import { Tool } from "routes/app/Class/stageview/components/Toolbar";
import { selectClass, setStudentTool } from "store/reducers/class/class";
import { useAppDispatch, useAppSelector } from "store/hooks";
import DeviceDialog from "./DeviceDialog";

const CamContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	position: "relative",
	overflow: "hidden",
	aspectRatio: "4/6",
	borderRadius: "5px",
	"&:hover": {
		div: {
			opacity: 1,
		},
	},
}));
const CamToolsContainer = styled(Grid)(({ theme }) => ({
	display: "flex",
	position: "absolute",
	padding: "5px",
	bottom: 0,
	left: 0,
	width: "100%",
	height: "50px",
	zIndex: 100,
	opacity: 0.25,
	transition: "all 0.5s ease",
}));

type Props = {
	studentTool: Tool | null;
	showToolButtons: boolean;
	bigCam: boolean;
	camOn: boolean;
	mute: boolean;
	toggleMic: () => void;
	toggleCam: () => void;
};
const Webcam = forwardRef(
	(
		{
			studentTool,
			showToolButtons,
			bigCam,
			camOn,
			mute,
			toggleCam,
			toggleMic,
		}: Props,
		ref
	) => {
		const { t } = useTranslation();
		const camRef = useRef<HTMLDivElement>(null);
		const { data, error, isLoading } = useJwtTokenQuery();
		const dispatch = useAppDispatch();
		const { roomId } = useAppSelector(selectClass);
		const [isFullScreen, setIsFullScreen] = useState(false);

		const API = useRef<IJitsiMeetExternalApi | null>(null);
		const [open, setOpen] = useState(false);

		const handleClose = () => {
			setOpen(false);
		};
		if (isLoading || !data) return <div>loading...</div>;
		if (!roomId) return <div>no room id</div>;

		return (
			<CamContainer
				container
				direction="column"
				justifyItems="center"
				justifyContent="center"
				id="container"
			>
				{/*<button onClick={() => setIsFullScreen(!isFullScreen)}>
						test
					</button>*/}
				<JaaSMeeting
					appId={
						"vpaas-magic-cookie-c4914d37f4ae4551942881ba2a88d601"
					} // TODO ENV
					// TODO GENERATE
					jwt={data.token}
					roomName={roomId}
					interfaceConfigOverwrite={{
						DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
						DISPLAY_WELCOME_FOOTER: false,
						MOBILE_APP_PROMO: false,
						// SETTINGS_SECTIONS: [],
						VIDEO_QUALITY_LABEL_DISABLED: true,
						SHOW_CHROME_EXTENSION_BANNER: false,
						TILE_VIEW_MAX_COLUMNS: 1,
					}}
					configOverwrite={{
						startWithAudioMuted: false,
						disableModeratorIndicator: true,
						startWithVideoMuted: false,
						//startScreenSharing: true,
						enableEmailInStats: false,
						prejoinPageEnabled: false,
						toolbarButtons: [],
						hideConferenceTimer: true,
						hideConferenceSubject: true,
						hideDisplayName: true,
						disableTileView: false,
						disableTileEnlargement: false,
						// notifications: {
						//     'connection.CONNFAIL', // shown when the connection fails,
						//     'dialog.cameraNotSendingData', // shown when there's no feed from user's camera
						//     'dialog.kickTitle', // shown when user has been kicked
						//     'dialog.liveStreaming', // livestreaming notifications (pending, on, off, limits)
						//     'dialog.lockTitle', // shown when setting conference password fails
						//     'dialog.maxUsersLimitReached', // shown when maximmum users limit has been reached
						//     'dialog.micNotSendingData', // shown when user's mic is not sending any audio
						//     'dialog.passwordNotSupportedTitle', // shown when setting conference password fails due to password format
						//     'dialog.recording', // recording notifications (pending, on, off, limits)
						//     'dialog.remoteControlTitle', // remote control notifications (allowed, denied, start, stop, error)
						//     'dialog.reservationError',
						//     'dialog.serviceUnavailable', // shown when server is not reachable
						//     'dialog.sessTerminated', // shown when there is a failed conference session
						//     'dialog.sessionRestarted', // show when a client reload is initiated because of bridge migration
						//     'dialog.tokenAuthFailed', // show when an invalid jwt is used
						//     'dialog.tokenAuthFailedWithReasons', // show when an invalid jwt is used with the reason behind the error
						//     'dialog.transcribing', // transcribing notifications (pending, off)
						//     'dialOut.statusMessage', // shown when dial out status is updated.
						//     'liveStreaming.busy', // shown when livestreaming service is busy
						//     'liveStreaming.failedToStart', // shown when livestreaming fails to start
						//     'liveStreaming.unavailableTitle', // shown when livestreaming service is not reachable
						//     'lobby.joinRejectedMessage', // shown when while in a lobby, user's request to join is rejected
						//     'lobby.notificationTitle', // shown when lobby is toggled and when join requests are allowed / denied
						//     'notify.chatMessages', // shown when receiving chat messages while the chat window is closed
						//     'notify.disconnected', // shown when a participant has left
						//     'notify.connectedOneMember', // show when a participant joined
						//     'notify.connectedTwoMembers', // show when two participants joined simultaneously
						//     'notify.connectedThreePlusMembers', // show when more than 2 participants joined simultaneously
						//     'notify.leftOneMember', // show when a participant left
						//     'notify.leftTwoMembers', // show when two participants left simultaneously
						//     'notify.leftThreePlusMembers', // show when more than 2 participants left simultaneously
						//     'notify.grantedTo', // shown when moderator rights were granted to a participant
						//     'notify.hostAskedUnmute', // shown to participant when host asks them to unmute
						//     'notify.invitedOneMember', // shown when 1 participant has been invited
						//     'notify.invitedThreePlusMembers', // shown when 3+ participants have been invited
						//     'notify.invitedTwoMembers', // shown when 2 participants have been invited
						//     'notify.kickParticipant', // shown when a participant is kicked
						//     'notify.linkToSalesforce', // shown when joining a meeting with salesforce integration
						//     'notify.moderationStartedTitle', // shown when AV moderation is activated
						//     'notify.moderationStoppedTitle', // shown when AV moderation is deactivated
						//     'notify.moderationInEffectTitle', // shown when user attempts to unmute audio during AV moderation
						//     'notify.moderationInEffectVideoTitle', // shown when user attempts to enable video during AV moderation
						//     'notify.moderationInEffectCSTitle', // shown when user attempts to share content during AV moderation
						//     'notify.mutedRemotelyTitle', // shown when user is muted by a remote party
						//     'notify.mutedTitle', // shown when user has been muted upon joining,
						//     'notify.newDeviceAudioTitle', // prompts the user to use a newly detected audio device
						//     'notify.newDeviceCameraTitle', // prompts the user to use a newly detected camera
						//     'notify.participantWantsToJoin', // shown when lobby is enabled and participant requests to join meeting
						//     'notify.passwordRemovedRemotely', // shown when a password has been removed remotely
						//     'notify.passwordSetRemotely', // shown when a password has been set remotely
						//     'notify.raisedHand', // shown when a partcipant used raise hand,
						//     'notify.startSilentTitle', // shown when user joined with no audio
						//     'notify.unmute', // shown to moderator when user raises hand during AV moderation
						//     'notify.videoMutedRemotelyTitle', // shown when user's video is muted by a remote party,
						//     'prejoin.errorDialOut',
						//     'prejoin.errorDialOutDisconnected',
						//     'prejoin.errorDialOutFailed',
						//     'prejoin.errorDialOutStatus',
						//     'prejoin.errorStatusCode',
						//     'prejoin.errorValidation',
						//     'recording.busy', // shown when recording service is busy
						//     'recording.failedToStart', // shown when recording fails to start
						//     'recording.unavailableTitle', // shown when recording service is not reachable
						//     'toolbar.noAudioSignalTitle', // shown when a broken mic is detected
						//     'toolbar.noisyAudioInputTitle', // shown when noise is detected for the current microphone
						//     'toolbar.talkWhileMutedPopup', // shown when user tries to speak while muted
						//     'transcribing.failedToStart', // shown when transcribing fails to start
						// },

						disabledNotifications: ["toolbar.talkWhileMutedPopup"],

						//disableResizable: true,
						//disableStageFilmstrip: true,
						//participantsPane: {
						//hideModeratorSettingsTab: true,
						//hideMoreActionsButton: true,
						//hideMuteAllButton: true,
						//}
					}}
					getIFrameRef={(camRef: any) => {
						camRef.style.aspectRatio = "4 / 6";
						camRef.style.width = "calc(100% + 18px)";
						camRef.style.margin = "-9px";
						camRef.style.userSelect = "none";
					}}
					onApiReady={(externalApi: any) => {
						//@ts-ignore TODO fix whatever it says
						ref.current = externalApi;

						externalApi.addListener("participantJoined", () => {
							externalApi.executeCommand("toggleTileView");
							// externalApi
							// 	.isVideoMuted()
							// 	.then((muted: boolean) => {
							// 		if (muted)
							// 			externalApi.executeCommand(
							// 				"toggleVideo"
							// 			);
							// 	});
						});
						externalApi.addListener(
							"tileViewChanged",
							(enabled: any) => {
								if (enabled.enabled) {
									return;
								} else {
									externalApi.executeCommand(
										"toggleTileView"
									);
								}
							}
						);
					}}
				/>
				{/* {showToolButtons && !bigCam && (
					<CamToolsContainer
						container
						item
						justifyContent="center"
						alignItems="end"
						gap="5px"
						style={{
							position: "absolute",
							top: "0px",
						}}
					>
						<Tooltip
							title={t("Student Pointer")}
							placement="top"
							TransitionComponent={Zoom}
						>
							<ToolButton
								variant="contained"
								disableElevation
								active={studentTool === Tool.Pointer}
								onClick={() =>
									dispatch(
										setStudentTool(
											studentTool === Tool.Pointer
												? null
												: Tool.Pointer
										)
									)
								}
							>
								<Fa
									icon={["far", "bullseye-pointer"]}
									fixedWidth
								/>
							</ToolButton>
						</Tooltip>
						<Tooltip
							title={t("Student Pen")}
							placement="top"
							TransitionComponent={Zoom}
						>
							<ToolButton
								variant="contained"
								disableElevation
								active={studentTool === Tool.Pen}
								onClick={() =>
									dispatch(
										setStudentTool(
											studentTool === Tool.Pen
												? null
												: Tool.Pen
										)
									)
								}
							>
								<Fa icon={["fas", "pen"]} fixedWidth />
							</ToolButton>
						</Tooltip>
						<Tooltip
							title={t("Student Eraser")}
							placement="top"
							TransitionComponent={Zoom}
						>
							<ToolButton
								variant="contained"
								disableElevation
								active={studentTool === Tool.Eraser}
								onClick={() =>
									dispatch(
										setStudentTool(
											studentTool === Tool.Eraser
												? null
												: Tool.Eraser
										)
									)
								}
							>
								<Fa icon={["far", "eraser"]} fixedWidth />
							</ToolButton>
						</Tooltip>
					</CamToolsContainer>
				)} */}
				<CamToolsContainer
					container
					item
					justifyContent="center"
					alignItems="end"
					gap="5px"
					style={{
						position: "absolute",
						top: "0px",
					}}
				>
					<Tooltip
						title={t("Mute Microphone")}
						placement="top"
						TransitionComponent={Zoom}
					>
						<ToolButton
							variant="contained"
							disableElevation
							onClick={toggleMic}
							style={mute ? { color: "red" } : {}}
						>
							<Fa
								icon={[
									"far",
									mute ? "microphone-slash" : "microphone",
								]}
								fixedWidth
							/>
						</ToolButton>
					</Tooltip>

					<Tooltip
						title={t("Turn Camera On/Off")}
						placement="top"
						TransitionComponent={Zoom}
					>
						<ToolButton
							variant="contained"
							disableElevation
							onClick={toggleCam}
							style={!camOn ? { color: "red" } : {}}
						>
							<Fa
								icon={[
									"far",
									camOn ? "camera-web" : "camera-web-slash",
								]}
								fixedWidth
							/>
						</ToolButton>
					</Tooltip>
					<Tooltip
						title={t("Device Settings")}
						placement="top"
						TransitionComponent={Zoom}
					>
						<ToolButton
							variant="contained"
							disableElevation
							onClick={() => setOpen(true)}
						>
							<Fa icon={["far", "gear"]} fixedWidth />
						</ToolButton>
					</Tooltip>
				</CamToolsContainer>
				<DeviceDialog open={open} API={ref} handleClose={handleClose} />
			</CamContainer>
		);
	}
);

export default Webcam;
