import { library } from '@fortawesome/fontawesome-svg-core'
import { faRocket } from '@fortawesome/pro-solid-svg-icons'
import {
    faStar,
    faLockKeyholeOpen,
    faLockKeyhole,
    faImages,
    faMicrophone,
    faFileDashedLine,
    faCalendarStar,
    faGaugeHigh,
    faGraduationCap,
    faRocketLaunch,
    faUserGraduate,
    faSchoolFlag,
    faRectangleHistory,
    faPlusCircle,
    faChalkboardUser
} from '@fortawesome/pro-thin-svg-icons'

library.add(
    faStar,
    faLockKeyholeOpen,
    faLockKeyhole,
    faImages,
    faMicrophone,
    faFileDashedLine,
    faCalendarStar,
    faRocketLaunch,
    faGaugeHigh,
    faGraduationCap,
    faUserGraduate,
    faSchoolFlag,
    faRectangleHistory,
    faPlusCircle,
    faChalkboardUser
)
