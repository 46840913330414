import { ItemType } from "store/reducers/class/items";
import { RawStage } from "store/reducers/class/stages";

export const generateEmptyWhiteboard = (id: string): RawStage => ({
	id,
	width: 910,
	height: 683,
	layers: [
		{
			id,
			items: [
				{
					id,
					type: ItemType.Rect,
					x: 0,
					y: 0,
					width: 910,
					height: 683,
					fill: "#ffffff",
				},
			],
		},
	],
});
