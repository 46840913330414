import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { useValidation } from "components/form/validators";
import { useTranslation } from "react-i18next";
import {
	useGetLessonInviteQuery,
	useGetLessonQuery,
	useIsValidLessonInvitationTokenMutation,
	useLessonInviteRespondMutation,
} from "rtk/api";
import {
	Link,
	LoaderFunctionArgs,
	Navigate,
	useLoaderData,
	useLocation,
	useSearchParams,
} from "react-router-dom";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { Button, Grid } from "styled/muiComponents";
import {
	Lesson,
	LessonInvite as ILessonInvite,
	SetPasswordCredentials,
} from "rtk/types";
import { tokenLoader } from "routes/router";
import { useAppSelector } from "store/hooks";
import { selectSession } from "store/reducers/session";
import { encode, decode } from "util/hashids";

import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

type LoaderRet = {
	token: string;
	response: "accept" | "reject";
};
export const lessonInviteLoader = ({
	params,
}: LoaderFunctionArgs): LoaderRet => {
	return {
		token: String(params.token),
		response: String(params.response) as "accept" | "reject",
	};
};
const ClassRoomContainer = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.primary.main,
	height: "100%",
	fontFamily: theme.typography.body1.fontFamily,
	backgroundSize: "cover",
}));
const InfoBubble = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.palette.white.light,
	padding: theme.spacing(3),
	borderRadius: "10px",
	marginTop: theme.spacing(2),
	boxShadow: "10px 5px 30px rgba(0,0,0,0.20)",
	maxWidth: "500px !important",
}));
const LessonInviteAccepted = ({ lessonId }: { lessonId: number }) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const {
		data: lesson,
		isLoading,
		isError,
		isSuccess,
		error,
	} = useGetLessonQuery(lessonId);
	//mutation to actually accept / reject
	if (isLoading) {
		return <Typography>loading Lesson information...</Typography>;
	}
	if (isError) {
		return (
			<Typography>
				error loading Lesson information...{" "}
				{JSON.stringify(error, null, 2)}
			</Typography>
		);
	}
	if (isSuccess) {
		return (
			<ClassRoomContainer
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			style={{ textAlign: "center", color: "#ffffff" }}
		>
			<img
				src="/assets/class-guru.svg"
				alt="Classuru"
				style={{ width: "100%", maxWidth: "100px" }}
			/>
			<Grid
				container
				item
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<InfoBubble item container direction="column" alignItems="center">
					<Typography
						variant="h1"
						style={{ lineHeight: 1.25 }}
						mt={0}
						mb={3}
					>
						Great, you have accepted to participate {lesson.name}
					</Typography>
					<table style={{ border: "0", textAlign: "left" }}>
						<tbody>
							<tr>
								<td><Typography variant="body1" mr={2}>{t("Teacher")}</Typography></td>
								<td><Typography variant="body1">{lesson.description}</Typography></td>
							</tr>
							<tr>
								<td><Typography variant="body1">{t("Start")}</Typography></td>
								<td><Typography variant="body1">{lesson.startAt}</Typography></td>
							</tr>
							<tr>
								<td><Typography variant="body1">{t("End")}</Typography></td>
								<td><Typography variant="body1">{lesson.endAt}</Typography></td>
							</tr>
						</tbody>
					</table>
					<Button
						color="secondary"
						variant="contained"
						href={`/classroom/${lesson.classroom}`}
						disableElevation
						mb={1} mt={3}
					>{t("Join class")}</Button>
					<Button
						color="secondary"
						variant="text"
						href={`/`}
						size="small"
						disableElevation
						mb={1}
					>{t("Back to home")}</Button>
				</InfoBubble>
			</Grid>
		</ClassRoomContainer>
		);
	}
	return null;
};

type Props = {
	lessonInvite: ILessonInvite;
	response: string;
};

const LessonInvite = ({ response, lessonInvite }: Props) => {
	const [respond, { isLoading, isSuccess, isError, error }] =
		useLessonInviteRespondMutation();

	useEffect(() => {
		respond({
			token: lessonInvite.token,
			response,
		});
	}, []);

	if (isLoading) {
		return <Typography>loading ...</Typography>; //TODO sergei?
	}

	if (isError) {
		return (
			<Typography>
				error ... <pre>{JSON.stringify(error, null, 2)}</pre>
			</Typography>
		); //TODO sergei?
	}

	if (isSuccess) {
		switch (response) {
			case "accept":
				return (
					<LessonInviteAccepted lessonId={lessonInvite.lessonId} />
				);
			case "reject":
				return (
					<Typography>
						Lesson invitation rejected we told your teacher as well
					</Typography>
				);
		}
	}

	return null;
};

const LessonInviteWrapper = () => {
	const { token, response } = useLoaderData() as LoaderRet;
	const {
		data: lessonInvite,
		isLoading,
		isError,
	} = useGetLessonInviteQuery(token);
	const session = useAppSelector(selectSession);
	const location = useLocation();

	if (isLoading) {
		return null; // TODO sergei? some loading indicator maybe?
	}
	if (isError) {
		return (
			<Typography>
				Well this didn't work, link seems to be invalid!
			</Typography> //TODO sergei
		);
	}

	if (lessonInvite && session.status === "loggedout") {
		return (
			<Navigate
				replace={true}
				to={`/login?e=${encode(lessonInvite.email)}&t=${
					lessonInvite.token
				}&ot=${encodeURIComponent(
					location.pathname + location.search
				)}`}
			/>
		);
	} else if (lessonInvite && session.status === "loggedin") {
		return <LessonInvite lessonInvite={lessonInvite} response={response} />;
	} else {
		return <Typography>checking session state ...</Typography>; //TODO sergei?
	}
};

export default LessonInviteWrapper;
