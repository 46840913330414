import "./App.css";
import { useEffect } from "react";
import { useAppDispatch } from "./store/hooks";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { useGetSessionInfoQuery } from "rtk/api";
import { setSession } from "store/reducers/session";
import { setNavbarState } from "store/reducers/app/reducer";
function App() {
	const { data, isSuccess, isError } = useGetSessionInfoQuery();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (isSuccess) {
			const { user, realm } = data;
			dispatch(
				setSession({
					user,
					realm,
					status: "loggedin",
				})
			);
			dispatch(setNavbarState("open"));
		} else if (isError) {
			dispatch(
				setSession({
					user: null,
					realm: null,
					status: "loggedout",
				})
			);
			dispatch(setNavbarState("closed"));
		}
	}, [dispatch, data, isSuccess, isError]);

	return (
		<ThemeProvider theme={theme}>
			<RouterProvider router={router} />
		</ThemeProvider>
	);
}

export default App;
