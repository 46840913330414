import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { useValidation } from "components/form/validators";
import { useTranslation } from "react-i18next";
import {
	useSetPasswordMutation,
	useIsValidEmailActivationTokenMutation,
} from "rtk/api";
import {
	Link,
	LoaderFunctionArgs,
	Navigate,
	useLoaderData,
	useSearchParams,
} from "react-router-dom";
import { Typography, styled } from "@mui/material";
import { useEffect } from "react";
import { Button, Grid } from "styled/muiComponents";
import { SetPasswordCredentials } from "rtk/types";
import {
	LoginWrapper,
	LoginContainer,
	LoadingBubble,
} from "routes/app/Class/stageview/constants/styledComponents";

export const setPasswordLoader = ({ params }: LoaderFunctionArgs): string =>
	String(params.token);

type SetPasswordProps = {
	token: string;
};

const FormField = styled(TextField)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

const SetPassword = ({ token }: SetPasswordProps) => {
	const { t } = useTranslation();
	const { required } = useValidation(t);
	const [searchParams] = useSearchParams();

	const [setPassword, { isLoading, isError, isSuccess, error }] =
		useSetPasswordMutation();

	const validate = ({
		password,
		confirmPassword,
	}: SetPasswordCredentials) => {
		if (password !== confirmPassword)
			return { confirmPassword: t("Passwords don't match") };
	};

	const handleSubmit = (
		credentials: Omit<SetPasswordCredentials, "token">
	) => {
		setPassword({
			...credentials,
			token,
		});
	};

	if (isSuccess) {
		return <Navigate to={`/login?${searchParams.toString()}`} />;
	}

	return (
			<LoginWrapper
				container
				justifyContent="center"
				alignItems="center"
				direction="column"
				wrap="nowrap"
			>
				<img
					src="/assets/class-guru.svg"
					alt="Classuru"
					style={{ height: "100px", marginBottom: "32px" }}
				></img>
				<Grid
					container
					item
					direction="row"
					justifyContent="center"
					alignItems="center"
				>
					<LoginContainer
						container
						item
						xs={12}
						sm={8}
						md={4}
						xl={3}
						direction={"column"}
						spacing={2}
					>
						<Grid
							container
							direction="column"
							item
							style={{ padding: 0 }}
						>
							<Grid item>
								<Typography variant="h1" mb={3}>
									{ t("Set your password") }
								</Typography>
							</Grid>
							<Grid item>

				<Form onSubmit={handleSubmit} validate={validate}>
					{({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<FormField
								name="password"
								type="password"
								label={t("password")}
								fieldProps={{ validate: required }}
								required
							/>
							<FormField
								name="confirmPassword"
								label={t("Confirm password")}
								type="password"
								fieldProps={{ validate: required }}
								required
							/>
							<Button
								type="submit"
								disabled={isLoading}
								color="primary"
								size="large"
								disableElevation
								variant="contained"
							>
								{t("set password")}
							</Button>
						</form>
					)}
				</Form>

							</Grid>
						</Grid> 
					</LoginContainer>
				</Grid>
			</LoginWrapper>
	)
};

const TokenValidation = () => {
	const token = useLoaderData() as ReturnType<typeof setPasswordLoader>;
	const [isTokenValid, { isSuccess, isError, isLoading }] =
		useIsValidEmailActivationTokenMutation();
	const { t } = useTranslation();

	useEffect(() => {
		isTokenValid(token);
	}, [isTokenValid, token]);

	if (isLoading) {
		return null; // some loading indicator maybe?
	}
	if (isError) {
		return (
			<Typography>
				{t("Dein Link ist bereits benutzt worden oder ist abgelaufen.")}
			</Typography>
		);
	}
	if (isSuccess) {
		return <SetPassword token={token} />;
	}
	return null;
};

export default TokenValidation;
