import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faStar,
    faLockKeyholeOpen,
    faLockKeyhole,
    faImages,
    faMicrophone,
    faFileDashedLine,
    faCalendarStar,
    faGaugeHigh,
    faGraduationCap,
    faUserGraduate,
    faSchoolFlag,
    faRectangleHistory,
    faXmark,
    faShuffle,
    faRightToBracket,
    faSearch,
    faPlusCircle,
    faGear,
    faArrowRightFromBracket,
    faUser,
    faTimer,
    faClock,
    faCalendarDays,
    faPaperPlane
} from '@fortawesome/pro-light-svg-icons'

library.add(
    faStar,
    faLockKeyholeOpen,
    faLockKeyhole,
    faImages,
    faMicrophone,
    faFileDashedLine,
    faCalendarStar,
    faGaugeHigh,
    faGraduationCap,
    faUserGraduate,
    faSchoolFlag,
    faRectangleHistory,
    faXmark,
    faShuffle,
    faRightToBracket,
    faSearch,
    faPlusCircle,
    faGear,
    faArrowRightFromBracket,
    faUser,
    faTimer,
    faClock,
    faCalendarDays,
    faPaperPlane
)