import { Line as KonvaLine } from "react-konva";
import { Line as ILine } from "store/reducers/class/items";

export type LineProps = Omit<ILine, "type" | "id">;

const Line = (props: LineProps) => {
	return <KonvaLine {...props} />;
};

export default Line;
