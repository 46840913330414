import {
	createBrowserRouter,
	LoaderFunctionArgs,
	Navigate,
	Outlet,
	redirect,
} from "react-router-dom";
import Dashboard from "./app/dashboard/Dashboard";
import DashboardStudent from "./app/dashboard/DashboardStudent";
import Material from "./app/material/Material";
import { playgroundRoute } from "./app/playground/Playground";
import Playground2 from "./app/playground/Playground2";
import RealClassRoom from "./app/Class/CRWrapper";
import ClassRoom from "./app/playground/classRoom";
import ClassRoom2 from "./app/playground/classRoom_2";
import TimeTable from "./app/timetable/TimeTable";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import Login from "./public/Login";
import Register from "./public/Register";
import SetPassword, { setPasswordLoader } from "./public/SetPassword";
import ResetPassword from "./public/ResetPassword";
import { Grid } from "@mui/material";
import NavBar from "components/navbar/navbar";
import { v4 as uuid } from "uuid";
import SlideCollectionFromPdf from "./app/material/slide_collections/create/SlideCollectionFromPdf";
import LessonInvite, { lessonInviteLoader } from "./public/LessonInvite";
import Profile from "./app/profile/Profile";
import Students from "./app/students/Students";
import Student from "./app/students/student/Student";
import Courses from "./app/courses/Courses";
import SlideCollectionView from "./app/material/slide_collections/view/SlideCollectionView";
import { Units } from "./app/courses/units/units";
import { UserRole } from "store/reducers/session";
import NavBarStudent from "components/navbar/navbarStudent";

const Root = () => {
	{/* ToDo condition for student (false) and teacher true*/}
	if (true) {
		return (
			<Grid container height="100%" wrap="nowrap" direction="column">
				<Grid container item >
					<NavBarStudent />
				</Grid>
				<Grid container item style={{ width: "100%", flexGrow: "1" }}>
					<Outlet />
				</Grid>
			</Grid>
		);
	} else {
		return (
			<Grid container height="100%" wrap="nowrap" direction="row">
				<Grid
					container
					item
					style={{ width: "auto", maxWidth: "60px" }}
				>
					<NavBar />
				</Grid>
				<Grid container item style={{ width: "100%", flexGrow: "1" }}>
					<Outlet />
				</Grid>
			</Grid>
		);
	}
};

export const tokenLoader = ({ params }: LoaderFunctionArgs): string =>
	String(params.token);

const ProtectedRoot = () => {};

const routes = createBrowserRouter([
	{
		element: <Root />,
		children: [
			{
				path: "lesson_invite/:token/:response",
				element: <LessonInvite />,
				loader: lessonInviteLoader,
			},
			{
				path: "/",
				element: <PublicRoute />,
				children: [
					{
						path: "/",
						element: <Navigate to="login" />,
					},
					{
						index: true,
						path: "login",
						element: <Login />,
					},
					{
						path: "register",
						element: <Register />,
					},
					{
						path: "confirm_email/:token",
						element: <SetPassword />,
						loader: setPasswordLoader,
					},
					{
						path: "new_password/:token",
						element: <SetPassword />,
						loader: setPasswordLoader,
					},
					{
						path: "reset_password",
						element: <ResetPassword />,
					},
				],
			},
			{
				element: <ProtectedRoute />,
				children: [
					{
						path: "dashboard",
						element: <Dashboard />,
					},
					{
						path: "timetable",
						element: <TimeTable />,
					},
					{
						path: "students",
						element: <Students />,
					},
					{
						path: "students/:id",
						element: <Student />,
					},
					{
						path: "profile",
						element: <Profile />,
					},
					playgroundRoute,
					{
						path: "Playground2",
						element: <Playground2 />,
					},
					// {
					// 	path: "classRoom",
					// 	element: <ClassRoom />,
					// },
					// {
					// 	path: "classRoom-2",
					// 	element: <ClassRoom2 />,
					// },
					{
						path: "slide_collections/create/pdf",
						element: <SlideCollectionFromPdf />,
					},
					{
						path: "slide_collections/:id?",
						element: <SlideCollectionView />,
						loader: ({ params }) => {
							if (params.id) {
								return Number(params.id);
							} else {
								return null;
							}
						},
					},
					{
						path: "courses",
						element: <Courses />,
					},
					{
						path: "courses/:course_id",
						element: <Units />,
					},
					{
						path: "material",
						element: <Material />,
					},
					{
						path: "classroom/:uuid?",
						element: <RealClassRoom />,
						loader: ({
							params,
						}: LoaderFunctionArgs): string | Response => {
							if (params.uuid) {
								return String(params.uuid);
							} else {
								return redirect(`${uuid()}`);
							}
						},
					},
				],
			},
		],
	},
]);

export default routes;
