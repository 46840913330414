import DialogTitle from "components/dialogs/DialogTitle";
import DialogContent from "components/dialogs/DialogContent";
import Button from "@mui/material/Button";
import DialogActions from "components/dialogs/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import NativeSelect from "@mui/material/NativeSelect";

const DeviceDialog = ({ open, API, handleClose }: any) => {
	const [currentDevices, setCurrentDevices] = useState<any>(null);
	const [availableDevices, setAvailableDevices] = useState<any>(null);
	const { t } = useTranslation();

	useEffect(() => {
		const getDevices = async () => {
			API.current?.getAvailableDevices().then((devices: any) => {
				setAvailableDevices(devices);
			});
		};

		const getCurrentDevices = async () => {
			API.current?.getCurrentDevices().then((devices: any) => {
				setCurrentDevices(devices);
			});
		};
		getDevices();
		getCurrentDevices();
	}, [open]);

	const handleChangeOutput = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		API.current?.setAudioOutputDevice(null, event.target.value);
	};

	const handleChangeInput = (event: React.ChangeEvent<HTMLSelectElement>) => {
		API.current?.setAudioInputDevice(null, event.target.value);
	};

	const handleChangeWebcam = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		API.current?.setAudioInputDevice(null, event.target.value);
	};

	console.log(currentDevices);

	if (!currentDevices || !availableDevices) {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth
				maxWidth="sm"
				//   PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
			>
				<DialogTitle
					id="draggable-dialog-title"
					onClose={handleClose}
					label="test"
				>
					{t("Devices")}
				</DialogTitle>
				<DialogContent>
					<p>{t("No devices found")}</p>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant="contained"
						disableElevation
						color="success"
					>
						{t("Schließen")}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth="sm"
			//   PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<DialogTitle
				id="draggable-dialog-title"
				onClose={handleClose}
				label="test"
			>
				{t("Devices")}
			</DialogTitle>
			<DialogContent>
				<>
					{currentDevices.audioInput && (
						<>
							{t("Microphone")}
							<NativeSelect
								onChange={handleChangeInput}
								defaultValue={currentDevices.audioInput.label}
								inputProps={{
									name: "audioIn",
									id: "uncontrolled-native",
								}}
								variant="outlined"
							>
								{availableDevices.audioInput.map(
									(device: any) => (
										<option value={device.deviceId}>
											{device.label}
										</option>
									)
								)}
							</NativeSelect>
						</>
					)}
					{currentDevices.audioOutput && (
						<>
							{t("Speaker")}
							<NativeSelect
								onChange={handleChangeOutput}
								defaultValue={currentDevices.audioOutput.label}
								inputProps={{
									name: "audioOut",
									id: "uncontrolled-native",
								}}
							>
								{availableDevices.audioOutput.map(
									(device: any) => (
										<option value={device.deviceId}>
											{device.label}
										</option>
									)
								)}
							</NativeSelect>
						</>
					)}

					{currentDevices.webcam && (
						<>
							<p>webcam</p>
							<NativeSelect
								onChange={handleChangeOutput}
								defaultValue={currentDevices.webcam.label}
								inputProps={{
									name: "webcam",
									id: "uncontrolled-native",
								}}
							>
								{availableDevices.webcam.map((device: any) => (
									<option value={device.deviceId}>
										{device.label}
									</option>
								))}
							</NativeSelect>
						</>
					)}
				</>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					variant="contained"
					disableElevation
					color="success"
				>
					{t("Close")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeviceDialog;
