import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { useValidation } from "components/form/validators";
import { useTranslation } from "react-i18next";
import { useResetPasswordMutation } from "rtk/api";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { Typography } from "styled/muiComponents";
import { styled } from "@mui/material/styles";
import { decode } from "util/hashids";
import {
	LoginWrapper,
	LoginContainer,
	LoadingBubble,
} from "routes/app/Class/stageview/constants/styledComponents";
import { ResetPasswordCredentials } from "rtk/types";

const FormField = styled(TextField)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

type FormState = "untouched" | "loading" | "error" | "success";

const toFormState = (isLoading: boolean, isError: boolean, isSuccess: boolean): FormState => {
	if (isLoading) {
		return "loading";
	}
	if (isError) {
		return "error";
	}
	if (isSuccess) {
		return "success";
	}
	return "untouched"
}

const ResetPassword = () => {
	const { t } = useTranslation();
	const { required } = useValidation(t);
	const [resetPassword, { isError, error, isSuccess, isLoading }] = useResetPasswordMutation();
	const [searchParams] = useSearchParams();
	const err = error as any;
	const params = {
		email: searchParams.get("e")
			? decode(searchParams.get("e") as string)
			: null,
		token: searchParams.get("t"),
	};

	const formState = toFormState(isLoading, isError, isSuccess);

	return (
		<>
			<LoginWrapper
				container
				justifyContent="center"
				alignItems="center"
				direction="column"
				wrap="nowrap"
			>
				<img
					src="/assets/class-guru.svg"
					alt="Classuru"
					style={{ height: "100px", marginBottom: "32px" }}
				></img>
				<Grid
					container
					item
					direction="row"
					justifyContent="center"
					alignItems="center"
				>
					<LoginContainer
						container
						item
						xs={12}
						sm={8}
						md={4}
						xl={3}
						direction={"column"}
						spacing={2}
					>
						<Grid
							container
							direction="column"
							item
							style={{ padding: 0 }}
						>
							<Grid item>
								<Typography variant="h1" mb={3}>
									{formState === "success" ? t("Success") : t("Reset Password")}
								</Typography>
							</Grid>
							{formState !== "success" && <Grid item>
								<Form onSubmit={resetPassword}>
									{({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											<FormField
												name="email"
												label={t("email")}
												fieldProps={{
													validate: required,
												}}
												required
												disabled={formState === "loading"}
											/>
											<Button
												color="primary"
												size="large"
												disableElevation
												variant="contained"
												type="submit"
												disabled={formState === "loading"}
											>
												{ formState === "loading" && <LoadingBubble /> }
												{t("Reset Password")}
											</Button>
										</form>
									)}
								</Form>
							</Grid>}
							{ formState === "success" && <Grid item>
								<Typography>
									{t("We have sent you an email with instructions to reset your password")}	
								</Typography>
							</Grid>}
						</Grid> 
						<Grid item mt={2} style={{ padding: 0 }}>
							{isError && err && (
								<Typography color="secondary">
									{err.data.error}
								</Typography>
							)}
							<Grid
								container
								direction="row"
								justifyContent="space-between"
							>
								<Button
									component={Link}
									size="small"
									to="/login"
								>
									{t("Login")}
								</Button>
								<Button
									component={Link}
									size="small"
									to={`/register?${searchParams.toString()}`}
								>
									{t("Registers")}
								</Button>
							</Grid>
						</Grid>
					</LoginContainer>
				</Grid>
			</LoginWrapper>
		</>
	);
};

export const loginRoute = {
	path: "/reset_password",
	element: <ResetPassword />,
};

export default ResetPassword;
