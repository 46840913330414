import { useGetSlideCollectionQuery } from "rtk/api";
import { SlideCollection } from "rtk/types";
import { LoadingBubble } from "../Class/stageview/constants/styledComponents";
import { RawStage } from "./slide_collections/view/SlideCollectionView";
import { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import styled from "@mui/material/styles/styled";
import { Button } from "@mui/material";

const StyledDiv = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	borderRadius: theme.spacing(1),
	backgroundColor: theme.palette.background.default,
	aspectRatio: "1/1",
}));
interface Props {
	material: SlideCollection;
	onClick: () => void;
	type: string; //expand when there are more material types
}

export const MaterialDisplay = ({ material, onClick, type }: Props) => {
	switch (type) {
		case "SLIDECOLLECTION":
			return (
				<SlideCollectionDisplay onClick={onClick} material={material} />
			);
		default:
			return <>error</>;
	}
};

const SlideCollectionDisplay = ({ onClick, material }: any) => {
	const { data: slideCollection, isLoading } = useGetSlideCollectionQuery(
		material.id
	);

	const previewRef = useRef<HTMLDivElement>(null);
	const [raw, setRaw] = useState({ width: 0, height: 0 });

	useEffect(() => {
		const handleResize = () => {
			if (previewRef.current && slideCollection?.slides?.[0]?.stage) {
				const { width, height } =
					previewRef.current.getBoundingClientRect();
				const scaleWidth =
					width / slideCollection.slides[0].stage.width;
				const scaleHeight =
					height / slideCollection.slides[0].stage.height;
				const scale = Math.min(scaleWidth, scaleHeight);

				setRaw({
					height: slideCollection.slides[0].stage.height * scale,
					width: slideCollection.slides[0].stage.width * scale,
				});
			}
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [raw.width, raw.height, slideCollection]);

	if (!slideCollection) return <LoadingBubble />;
	return (
		<StyledDiv ref={previewRef}>
			<Button
				onClick={onClick}
				variant="outlined"
				style={{ height: "100%", width: "100%" }}
			>
				{slideCollection?.slides?.length > 0 ? (
					<RawStage
						stage={slideCollection.slides[0].stage}
						width={raw.width - 32}
						height={raw.height - 32}
					/>
				) : (
					<div
						style={{
							color: "lightgrey",
							fontSize: "3em",
							textTransform: "none",
						}}
					>
						X__X
					</div>
				)}
			</Button>
		</StyledDiv>
	);
};
