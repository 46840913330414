import {
	CountdownContainer,
	NavBar,
	SearchField,
	SlideNavButton,
	ToolButtonContainer,
} from "../constants/styledComponents";
import Tooltip from "@mui/material/Tooltip";
import ToolButton from "../components/ToolButton";
import { Button, Fa, Grid } from "styled/muiComponents";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useTranslation } from "react-i18next";
import Zoom from "@mui/material/Zoom";
import Stars from "../../components/Stars";
import Countdown from "../../components/Countdown";
import { MediaToolBar } from "../constants/styledComponents";
import { useAppSelector, useAppDispatch } from "store/hooks";
import {
	selectClass,
	View,
	nextWhiteboard,
	nextStage,
	prevStage,
	prevWhiteboard,
	setStars,
} from "store/reducers/class/class";
import { FormEvent, useEffect, useRef, useState, FocusEvent } from "react";
import { createWhiteboard } from "store/reducers/class/stages";
import { generateEmptyWhiteboard } from "../constants/generateEmptyWhiteboard";
import { v4 as uuid } from "uuid";
import { ShowItemsState } from "../../classRoom";
import { useNavigate } from "react-router-dom";

/* 
	onsubmit
	showstages
	showbackground
	handleStarchange
	handleshowstages

*/

type Props = {
	handleSubmitSearchField: (value: string) => void;
	isTeacher: boolean;
	showItems: ShowItemsState;
	setShowItems: React.Dispatch<React.SetStateAction<ShowItemsState>>;
	// teacher
	// handleShow
	// handleStarChange // maybe not prop but import?
	// showbackground/showstage
};

export const ClassroomButtons = ({
	handleSubmitSearchField,
	isTeacher,
	showItems,
	setShowItems,
}: Props) => {
	const classRoom = useAppSelector(selectClass);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [activeStageIndex, setActiveStageIndex] = useState<number>(0);
	const [activeWhiteboardIndex, setActiveWhiteboardIndex] =
		useState<number>(0);
	const dispatch = useAppDispatch();
	const textFieldRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		setActiveStageIndex(
			classRoom.stageIds.findIndex((id) => id === classRoom.activeStageId)
		);
	}, [classRoom.activeStageId]);

	useEffect(() => {
		setActiveWhiteboardIndex(
			classRoom.whiteboardIds.findIndex(
				(id) => id === classRoom.activeWhiteboardId
			)
		);
	}, [classRoom.activeWhiteboardId]);

	useEffect(() => {
		if (textFieldRef.current === null) return;
		if (classRoom.view === View.Background) {
			// textFieldRef.current!.value = searchQuery;
		} else {
			textFieldRef.current!.value = "slideSearch here";
		}
	}, [classRoom.view]);

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		handleSubmitSearchField(textFieldRef.current!.value);
	};

	const handleFocusSearchField = (e: FocusEvent) => {
		if (classRoom.view === View.Presentation) {
			setShowItems((prev) => ({ ...prev, stages: true }));
		}
		if (classRoom.view === View.Background) {
			setShowItems((prev) => ({ ...prev, background: true }));
		}
	};

	const handleClickLeave = () => {
		navigate("/dashboard");
	};

	const handlePrev = () => {
		switch (classRoom.view) {
			case View.Presentation:
				dispatch(prevStage());
				break;
			case View.Whiteboard:
				dispatch(prevWhiteboard());
				break;
		}
	};

	const handleNext = () => {
		switch (classRoom.view) {
			case View.Presentation:
				dispatch(nextStage());
				break;
			case View.Whiteboard:
				if (
					activeWhiteboardIndex ===
					classRoom.whiteboardIds.length - 1
				) {
					dispatch(createWhiteboard(generateEmptyWhiteboard(uuid())));
				}
				dispatch(nextWhiteboard());
				break;
		}
	};

	const handleShowStages = () => {
		if (classRoom.view === View.Background) {
			setShowItems((prev) => ({ ...prev, background: !prev.background }));
		} else {
			setShowItems((prev) => ({ ...prev, stages: !prev.stages }));
		}
	};

	const handleStarsChange = (index: number) => {
		dispatch(
			setStars(classRoom.stars === index ? classRoom.stars - 1 : index)
		);
	};

	if (isTeacher) {
		return (
			<NavBar
				container
				item
				direction="row"
				justifyContent="center"
				alignItems="center"
				wrap="nowrap"
			>
				<ToolButtonContainer
					justifyContent="flex-start"
					alignItems="center"
					id="slideNavContainer"
					style={
						classRoom.view === View.Presentation ||
						classRoom.view === View.Whiteboard ||
						classRoom.view === View.Background ||
						classRoom.view === View.Video ? { display: "flex" } : { display: "none" }}
				>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						style={{
							border: "1px solid rgba(255, 255, 255, 0.15)",
							borderRadius: "15px",
							marginLeft: "5px",
							display: "flex",
							flexWrap: "nowrap",
							alignContent: "center",
							alignItems: "center",
							padding: "0px"
						}}
					>
						<Tooltip
							title={
								showItems.stages ? t("Close") : t("Select Slide")
							}
							placement="bottom"
							TransitionComponent={Zoom}
						>
							<ToolButton
								onClick={handleShowStages}
								variant="contained"
								disableElevation
								active={showItems.stages || showItems.background}
								style={{ margin: "0 5px 0 0" }}
							>
								<Fa
									icon={[
										showItems.stages || showItems.background ? "far" : "fas",
										showItems.stages || showItems.background
											? "square-xmark"
											: "square-dashed",
									]}
									fixedWidth
								/>
							</ToolButton>
						</Tooltip>

						<form onSubmit={handleSubmit} style={{
							display: "flex",
							flex: 1,
							flexWrap: "nowrap",
							alignContent: "center",
							alignItems: "center",
						}}>
							<SearchField
								onFocus={handleFocusSearchField}
								inputRef={textFieldRef}
								id="outlined-basic"
								placeholder="Click to search..."
								variant="outlined"
								size="small"
								autoComplete="off"
							/>
							<ToolButton
								variant="contained"
								disableElevation
								type="submit"
							>
								<Fa icon={["far", "magnifying-glass"]} fixedWidth />
							</ToolButton>
						</form>
					</ButtonGroup>
				</ToolButtonContainer>
				<Grid
					container
					item
					direction="row"
					justifyContent="center"
					alignItems="center"
					style={{
						display:
							classRoom.view === View.Background
								? "flex"
								: "none",
						position: "absolute",
						paddingRight: "calc(20% - 175px)",
						paddingLeft: "15px",
					}}
				>
					<ButtonGroup
						variant="outlined"
						aria-label="outlined button group"
						style={{
							border: "1px solid rgba(255, 255, 255, 0.15)",
							borderRadius: "15px",
							display: "flex",
							flexWrap: "nowrap",
							alignContent: "center",
							alignItems: "center",
							padding: "0px"
						}}
					>
						<Tooltip
							title={t("Background Color")}
							placement="bottom"
							TransitionComponent={Zoom}
							arrow
						>
							<SlideNavButton
								onClick={handlePrev}
								variant="contained"
								size="small"
								disableElevation
								style={{ borderTopLeftRadius: "15px", borderBottomLeftRadius: "15px" }}
							>
								<Fa icon={["far", "images"]} fixedWidth />
							</SlideNavButton>
						</Tooltip>
						<Tooltip title={"Choose Color"} placement="bottom" TransitionComponent={Zoom}>
							<SlideNavButton
								onClick={handlePrev}
								variant="contained"
								size="small"
								disableElevation
								style={{ borderRadius: 0, borderLeft: "1px solid rgba(255, 255, 255, 0.15)", backgroundColor: "rgba(255, 255, 255, 1)" }}
							>
								
							</SlideNavButton>
						</Tooltip>
						<Tooltip title={"Reset"} placement="bottom" TransitionComponent={Zoom}>
							<SlideNavButton
								onClick={handlePrev}
								variant="contained"
								size="small"
								disableElevation
								style={{ borderTopRightRadius: "15px", borderBottomRightRadius: "15px", borderLeft: "1px solid rgba(255, 255, 255, 0.15)", }}
							>
								<Fa icon={["far", "times"]} fixedWidth />
							</SlideNavButton>
						</Tooltip>
					</ButtonGroup>
				</Grid>
				<Grid
					container
					item
					direction="row"
					justifyContent="center"
					alignItems="center"
					style={{
						display:
							classRoom.view === View.Presentation ||
							classRoom.view === View.Whiteboard
								? "flex"
								: "none",
						position: "absolute",
						paddingRight: "calc(20% - 170px)",
						paddingLeft: "15px",
					}}
				>
					<Tooltip
						title={t("Prev. Slide")}
						placement="bottom"
						TransitionComponent={Zoom}
						arrow
					>
						<SlideNavButton
							onClick={handlePrev}
							variant="contained"
							size="small"
							disableElevation
							disabled={
								(classRoom.view === View.Presentation &&
									activeStageIndex === 0) ||
								(classRoom.view === View.Whiteboard &&
									activeWhiteboardIndex === 0)
							}
						>
							<Fa icon={["far", "arrow-left"]} fixedWidth />
						</SlideNavButton>
					</Tooltip>
					<Grid mx={1}>
						<Tooltip
							title={t("Select Slide")}
							placement="bottom"
							TransitionComponent={Zoom}
							arrow
						>
							<Button
								onClick={handleShowStages}
								variant="contained"
								size="small"
								disableElevation
							>
								{classRoom.view === View.Presentation
									? `${activeStageIndex + 1} / ${
											classRoom.stageIds.length
									  }`
									: `${activeWhiteboardIndex + 1} / ${
											classRoom.whiteboardIds.length
									  }`}
							</Button>
						</Tooltip>
					</Grid>
					<Tooltip
						title={t("Next Slide")}
						placement="bottom"
						TransitionComponent={Zoom}
						arrow
					>
						<SlideNavButton
							onClick={handleNext}
							variant="contained"
							size="small"
							disableElevation
							disabled={
								classRoom.view === View.Presentation &&
								activeStageIndex >=
									classRoom.stageIds.length - 1
							}
						>
							<>
								{(classRoom.view === View.Presentation ||
									(classRoom.view === View.Whiteboard &&
										activeWhiteboardIndex <
											classRoom.whiteboardIds.length -
												1)) && (
									<Fa
										icon={["far", "arrow-right"]}
										fixedWidth
									/>
								)}
								{classRoom.view == View.Whiteboard &&
									activeWhiteboardIndex ===
										classRoom.whiteboardIds.length - 1 && (
										<Fa icon={["far", "plus"]} fixedWidth />
									)}
							</>
						</SlideNavButton>
					</Tooltip>
				</Grid>
				<Grid
					container
					item
					direction="row"
					justifyContent="flex-end"
					alignItems="center"
					gap="15px"
				>
					<Stars
						variant="contained"
						value={classRoom.stars}
						onChange={handleStarsChange}
					/>
					<CountdownContainer>
						<Countdown initialTime={25 * 60} />
					</CountdownContainer>
					<MediaToolBar style={{ padding: 0 }}>
						<Tooltip
							title={t("Leave Classroom")}
							placement="left"
							TransitionComponent={Zoom}
							arrow
						>
							<ToolButton
								variant="contained"
								disableElevation
								onClick={handleClickLeave}
								// onClick={() => goto("/dashboard")}
							>
								<Fa
									icon={["fas", "right-from-bracket"]}
									fixedWidth
								/>
							</ToolButton>
						</Tooltip>
					</MediaToolBar>
				</Grid>
			</NavBar>
		);
	} else {
		<Grid
			container
			item
			direction="row"
			justifyContent="center"
			alignItems="center"
			wrap="nowrap"
		>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				style={{ height: "60px" }}
			>
				<Stars
					variant="spread"
					value={classRoom.stars}
					onChange={handleStarsChange}
					disabled={true}
				/>
			</Grid>
		</Grid>;
	}

	return <></>;
};
