import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { PageContainer } from "components/pages/PageContainer";
import { useTranslation } from "react-i18next";
import { selectSession, UserRole } from "store/reducers/session";
import { useAppSelector } from "store/hooks";
import { setNavbarState } from "store/reducers/app/reducer";

import { Button, Fa } from "styled/muiComponents";

import Card from "@mui/material/Card";

import { UpcommingLessons } from "./UpcommingLessons";

const StyledCard = styled(Card)(({ theme }) => ({
	padding: `${theme.spacing(3)}`,
	marginBottom: `${theme.spacing(2)}`,
	borderRadius: `${theme.spacing(2)}`,
}));
const DashboardStudent: React.FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(setNavbarState("open"));
	}, []);

	const session = useAppSelector(selectSession);

	return (
		<PageContainer maxWidth="lg">
			<Grid
				container
				direction="row"
				spacing={2}
				alignItems="start"
				style={{ overflow: "hidden" }}
				item
			>
				<Grid item md={9} style={{ width: "100%", paddingTop: "32px" }}>
					<UpcommingLessons />
				</Grid>

				<Grid item md={3}>
				<Typography variant="h2" mb={3} mt={2}>
						Your Curent Plan
					</Typography>
					<StyledCard elevation={0} variant="outlined">
						<Grid container direction="column" style={{alignItems: "flex-start"}}>
							<Typography variant="h5">
								<strong>{t("Recurring plan")}</strong>
							</Typography>
							<Typography variant="body2">
								<strong>8 Classes / month</strong>
							</Typography>
							<Typography variant="body2">
								{t(
									"3 of 8 classes used this month. Your next class is on 12.11.2024."
								)}
							</Typography>
							<Button
								color="primary"
								variant="contained"
								disableElevation
								style={{ color: "white" }}
								size="small"
								mt={2}
							>
								{/* ToDo condition for student (false) and teacher true*/}
								{t("Change plan")}
							</Button>
						</Grid>
					</StyledCard>
					<Typography variant="h2" mb={3} mt={3}>
						Past Classes
					</Typography>
					<StyledCard elevation={0} variant="outlined">
						<Grid container direction="column" style={{alignItems: "flex-start"}}>
							<Typography variant="h5">
								<strong>{t("Teachter Max | Student Juri")}</strong>
							</Typography>
							<Typography variant="body2">
								<strong>08.08.2024</strong>
							</Typography>
							<Typography variant="body2">
								{t(
									"Class was about the basics of programming. We learned about variables and loops."
								)}
							</Typography>
							<Button
								color="primary"
								variant="contained"
								disableElevation
								style={{ color: "white" }}
								size="small"
								mt={2}
							>
								{/* ToDo condition for student (false) and teacher true*/}
								{t("Show details")}
							</Button>
						</Grid>
					</StyledCard>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default DashboardStudent;
