import React, { useEffect, useState } from "react";
import {
	useCreateFolderMutation,
	useGetFolderQuery,
	useGetFoldersQuery,
} from "rtk/api"; // Adjust the path to your GraphQL queries/mutations

import { FolderChild, Folder as IFolder, SlideCollection } from "rtk/types";
import { Dialog, Grid } from "@mui/material";
import Folder from "./Folder";
import { Button, Fa, FaButton } from "styled/muiComponents";
import { useNavigate } from "react-router-dom";
import SlideCollectionFromPdf from "routes/app/material/slide_collections/create/SlideCollectionFromPdf";
import { SlideCollectionViewContainerWithId } from "routes/app/material/slide_collections/view/SlideCollectionView";
import { styled } from "@mui/material/styles";

interface ExplorerProps {
	folderId?: number;
}

const LeftGrid = styled(Grid)(({ theme }) => ({
	minWidth: "300px",
}));

const Explorer: React.FC<ExplorerProps> = ({ folderId }) => {
	if (folderId != undefined) {
		return <CourseExplorer id={folderId} />;
	} else {
		return <AllExplorer />;
	}
};

export default Explorer;

const AllExplorer = () => {
	const {
		data: folders,
		isLoading,
		isSuccess,
		isError,
	} = useGetFoldersQuery();

	const [activeFolder, setActiveFolder] = useState<IFolder | null>();

	useEffect(() => {
		if (
			isSuccess &&
			folders &&
			activeFolder === null &&
			folders.length > 0
		) {
			setActiveFolder(folders[0]);
		}
	}, [isSuccess, folders, activeFolder]);
	if (isError) return <div>Error...</div>;
	if (!isLoading)
		return (
			<div style={{ width: "100%", textAlign: "center" }}>Loading...</div>
		);
	return (
		<Grid container style={{ height: "100%" }} gap={2}>
			<LeftSide
				isLoading={isLoading}
				isSuccess={isSuccess}
				folders={folders}
				setActiveFolder={setActiveFolder}
			/>
			<RightSide
				activeFolder={activeFolder}
				setActiveFolder={setActiveFolder}
			/>
		</Grid>
	);
};

const CourseExplorer = ({ id }: any) => {
	const {
		data: folder,
		isSuccess,
		isLoading,
		isError,
	} = useGetFolderQuery({ id: id });
	console.log(isError, folder);

	// @ts-ignore
	const [activeFolder, setActiveFolder] = useState<IFolder | null>(null);

	useEffect(() => {
		console.log(isSuccess, folder, activeFolder === null);
		console.log(folder, activeFolder);
		if (isSuccess && folder && activeFolder === null) {
			// console.log("reaches?");
			setActiveFolder(folder);
		}
	}, [isSuccess, folder, activeFolder, isLoading]);

	if (isError) return <div>Error...</div>;
	if (isLoading && !activeFolder)
		return (
			<div style={{ width: "100%", textAlign: "center" }}>Loading...</div>
		);

	if (activeFolder === null) {
		return <div>currently no active folder</div>;
	}
	return (
		<Grid container style={{ height: "100%" }} gap={2}>
			<LeftSide
				isLoading={isLoading}
				isSuccess={isSuccess}
				folders={folder!.children}
				setActiveFolder={setActiveFolder}
			/>
			<RightSide
				activeFolder={activeFolder}
				setActiveFolder={setActiveFolder}
			/>
		</Grid>
	);
};

const LeftSide = ({ isLoading, isSuccess, folders, setActiveFolder }: any) => {
	const navigate = useNavigate();
	const handleClick = (folder: IFolder | FolderChild) => {
		console.log("onClick", folder);
		if ("kind" in folder) {
			let child = folder as FolderChild;
			switch (child.kind) {
				case "SLIDE_COLLECTION":
					navigate(`/slide_collections/${child.id}`);
					break;
				default:
					console.error("unsupported child kind click!", child);
			}
		} else {
			setActiveFolder(folder as IFolder);
		}
	};

	return (
		<LeftGrid item xs={3}>
			<div style={{ width: "100%", height: "100%" }}>
				{isLoading && (
					<div style={{ width: "100%", textAlign: "center" }}>
						Loading...
					</div>
				)}
				{!isLoading &&
					isSuccess &&
					folders &&
					folders.map((folder: IFolder) => (
						<Folder
							key={folder.id}
							view="nav"
							id={folder.id}
							onClick={handleClick}
							depth={0}
						></Folder>
					))}
			</div>
		</LeftGrid>
	);
};

const RightSide = ({ activeFolder, setActiveFolder }: any) => {
	console.log(activeFolder);
	const [createFolder] = useCreateFolderMutation();
	const [openAddSlideCollection, setOpenAddSlideCollection] = useState(false);
	const [slideCollectionSelected, setSlideCollectionSelected] =
		useState<SlideCollection | null>(null);

	const navigate = useNavigate();
	const handleClick = (folder: IFolder | FolderChild) => {
		console.log("onClick", folder);
		if ("kind" in folder) {
			let child = folder as FolderChild;
			switch (child.kind) {
				case "SLIDE_COLLECTION":
					// navigate(`/slide_collections/${child.id}`);
					setSlideCollectionSelected(folder as any);
					break;
				default:
					console.error("unsupported child kind click!", child);
			}
		} else {
			setActiveFolder(folder as IFolder);
		}
	};

	const handleAddSlideCollection = () => {
		setOpenAddSlideCollection(!openAddSlideCollection);
	};

	const handleCloseUpload = () => {
		setOpenAddSlideCollection(false);
	};

	// const handleView = () => {
	// 	setOpenView();
	// };

	const handleCreateFolder = async () => {
		// Use createFolder mutation to create a new folder
		let name = prompt("pick a name");
		if (name === null) return;
		if (!activeFolder) return;

		try {
			await createFolder({
				category: "MATERIAL",
				name,
				parentId: activeFolder.id,
			});
		} catch (error) {
			// Handle errors
			console.error(error);
		}
	};
	return (
		<Grid container direction="column" item xs>
			{activeFolder ? (
				<>
					<Grid
						container
						item
						direction="row"
						alignItems="center"
						mb={3}
					>
						<Grid item style={{ flexGrow: 1 }}>
							<h1 style={{ margin: 0 }}>{activeFolder.name}</h1>
						</Grid>
						<Grid item>
							<Button
								color="primary"
								variant="contained"
								onClick={handleCreateFolder}
								mr={1}
							>
								<Fa
									icon={["fa" as any, "folder"]}
									style={{ marginRight: "8px" }}
								/>
								Add Folder
							</Button>
							<Button
								color="primary"
								variant="contained"
								onClick={handleAddSlideCollection}
								style={{}}
							>
								<Fa
									icon={["fa" as any, "chalkboard"]}
									style={{ marginRight: "8px" }}
								/>
								Upload File
							</Button>
						</Grid>
					</Grid>
					<Grid item style={{ flexGrow: 1 }}>
						<Folder
							key={activeFolder.id}
							view="tiles"
							id={activeFolder.id}
							onClick={handleClick}
							depth={0}
						/>
					</Grid>
				</>
			) : null}
			<Dialog
				open={openAddSlideCollection}
				onClose={handleCloseUpload}
				fullScreen
			>
				<SlideCollectionFromPdf folderId={activeFolder.id} />
			</Dialog>

			<Dialog
				open={!!slideCollectionSelected}
				onClose={() => setSlideCollectionSelected(null)}
			>
				{slideCollectionSelected && (
					<SlideCollectionViewContainerWithId
						id={slideCollectionSelected!.id}
					/>
				)}
			</Dialog>
		</Grid>
	);
};
