import { Middleware } from "@reduxjs/toolkit";

const timerMiddleware: Middleware = store => {

  let intervalId: any;
  return next => action => {
    switch (action.type) {
        case "class/startTimer":
            intervalId = setInterval(() => {
                store.dispatch({type: "class/tick"})
            }, 1000)
        break;
        case "class/stopTimer":
            clearInterval(intervalId)
        break;
    }
    next(action)
  }
}


 
export default timerMiddleware;