import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Item, RawItem, Loadable } from './items';
import { init } from './class';
import { RootState } from 'store/store';
import { create as createItem, remove as removeItem } from './items';
import { create as createStage, createWhiteboard } from './stages';

export const drawingLayerId = (stageId: string) => `${stageId}-drawing`;

export type RawLayer = Omit<Layer, "itemIds"> & { items: RawItem[] }
export type Layer = {
  id: string
  itemIds: string[]
}

type LayerState = {
  layerById: {[key: string]: Layer},
}


const initialState: LayerState = {
  layerById: {},
};

export const layerSlice = createSlice({
  name: 'layer',
  initialState,
  reducers: {
    create: (state, { payload }: PayloadAction<{ stageId: string, layerId: string, items?: Item[]}>) => {
      const { layerId, items } = payload;
      state.layerById[layerId] = {
        id: layerId,
        itemIds: items ? items.map(item => item.id) : []
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(init, ( state, { payload }) => {
        state.layerById = payload.stages.map(stage => stage.layers).flat().reduce((res, cur) => ({...res, [cur.id]: {...cur, itemIds: cur.items.map(item => item.id), items: undefined}}), {})
      })
      .addCase(createItem, (state, { payload }) => {
        const { layerId, item }  = payload;
        state.layerById[layerId].itemIds.push(item.id);
        return state;
      })
      .addCase(removeItem, (state, { payload }) => {
        let itemIds: string[];
        if(typeof payload === "string") {
          itemIds = [payload]
        } else {
          itemIds = payload
        }
        Object.keys(state.layerById).forEach(layerId => {
          state.layerById[layerId].itemIds = state.layerById[layerId].itemIds.filter(itemId => !itemIds.includes(itemId))
        })
      })
      .addCase(createStage, (state, { payload }) => {
        payload.layers.forEach((layer: RawLayer) => {
          state.layerById[layer.id] = { ...layer, itemIds: layer.items.map((item: RawItem) => item.id) }
        })
      })
      .addCase(createWhiteboard, (state, { payload }) => {
        payload.layers.forEach((layer: RawLayer) => {
          state.layerById[layer.id] = { ...layer, itemIds: layer.items.map((item: RawItem) => item.id) }
        })
      })
  },
});

export const selectLayer = (id: string) => (state: RootState) => {
  return state.layers.layerById[id];
}

const selectLoadableIds = createSelector([
  (state: RootState, id: string) => state.layers.layerById[id], 
  (state: RootState, id: string) => state.items.itemById
],
  (layer, itemById) => layer.itemIds.filter(itemId => ("loadingState" in itemById[itemId]))
)

export const selectLoadablesFromLayer = createSelector(
  [
    selectLoadableIds,
    (state: RootState) => state.items.itemById
  ], 
  (loadableIds, itemById): Loadable[] => loadableIds.map(itemId => itemById[itemId]) as Loadable[]
)

export const { create } = layerSlice.actions;

export default layerSlice.reducer;
