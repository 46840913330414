import { Grid, Typography } from "@mui/material";
import { Button } from "styled/muiComponents";
import { useAppSelector } from "store/hooks";
import { PageContainer } from "components/pages/PageContainer";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import { TextField } from "mui-rff";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import { FormEvent, useState } from "react";
import Dialog from "@mui/material/Dialog";
import MuiDialog from "components/dialogs/Dialog";
import MuiDialogTitle from "components/dialogs/DialogTitle.jsx";
import DialogContent from "components/dialogs/DialogContent.jsx";
import DialogActions from "components/dialogs/DialogActions.jsx";
import { Form } from "react-final-form";

import {
	useChangePasswordMutation,
	useUpdateSessionInfoMutation,
} from "rtk/api";
import { ChangePassword } from "rtk/types";

const StyledCard = styled(Card)(({ theme }) => ({
	padding: `${theme.spacing(4)}`,
	marginBottom: `${theme.spacing(2)}`,
	borderRadius: `${theme.spacing(2)}`,
	width: "100%",
}));

const Entry = styled(Typography)(({ theme }) => ({
	fontSize: "1.1em",
}));
const FormField = styled(TextField)(({ theme }) => ({
	marginTop: theme.spacing(2),
}));

const Profile = () => {
	const session = useAppSelector((state) => state.session);

	const [updateSessionInfo] = useUpdateSessionInfoMutation();
	const [changePassword, { isLoading }] = useChangePasswordMutation();

	const [editStates, setEditStates] = useState({
		editRealm: false,
		editUser: false,
	});

	const handleEditRealm = () => {
		setEditStates((prev) => ({ ...prev, editRealm: true }));
	};

	const handleEditUser = () => {
		setEditStates((prev) => ({ ...prev, editUser: true }));
	};

	const handleSaveRealm = async (values: any) => {
		await updateSessionInfo({
			user: session.user!,
			realm: { ...session.realm!, ...values },
		});

		setEditStates((prev) => ({ ...prev, editRealm: false }));
	};

	const handleSaveUser = async (values: Record<string, string>) => {
		await updateSessionInfo({
			user: {
				...session.user!,
				...values,
			},
			realm: session.realm!,
		});

		setEditStates((prev) => ({ ...prev, editUser: false }));
	};

	const handleChangePassword = async (values: ChangePassword) => {
		await changePassword(values);
	};

	const initialUserValues = { ...session.user };
	const initialRealmValues = { ...session.realm };

	const { t } = useTranslation();
	return (
		<PageContainer maxWidth="sm">
			<Typography variant="h1" mb={4}>
				{t("Profile")}
			</Typography>
			<Grid container direction="column" spacing={2} alignItems="start">
				<Grid item container>
					<StyledCard variant="outlined">
						<MuiDialog
							open={editStates.editUser}
							onClose={() =>
								setEditStates((prev) => ({
									...prev,
									editUser: false,
								}))
							}
						>
							<MuiDialogTitle>
								{t("Edit Your Details")}
							</MuiDialogTitle>
							<DialogContent>
								<Form
									onSubmit={handleSaveUser}
									initialValues={initialUserValues}
								>
									{({ handleSubmit }) => (
										<form onSubmit={handleSubmit}>
											{editStates.editUser}
											<Typography variant="h2" mb={2}>
												{t("Your Details")}
											</Typography>
											<Divider />
											<Grid
												item
												container
												direction="row"
												spacing={2}
												mt={0}
											>
												<Grid item>
													<Avatar
														alt="Name"
														src="/assets/class-guru.svg"
														sx={{
															width: 128,
															height: 128,
															border: "1px solid rgba(0,0,0,0.15)",
														}}
													/>
												</Grid>
												<Grid item>
													<Entry variant="body2">
														<FormField
															label={t(
																"Username"
															)}
															name="username"
														/>
													</Entry>
													<Entry>
														<FormField
															label={t("E-Mail")}
															disabled
															name="email"
														/>
													</Entry>

													<Entry
														variant="body2"
														mb={3}
													>
														<FormField
															label={t(
																"First Name"
															)}
															name="firstName"
														/>
														<FormField
															label={t(
																"Last Name"
															)}
															name="lastName"
														/>
													</Entry>
													<Entry variant="body2">
														<FormField
															name="website"
															label={t("Website")}
														/>
													</Entry>
													<Button
														variant="contained"
														color="primary"
														disableElevation
														mt={2}
														type="submit"
														// onClick={handleSaveUser}
													>
														{t("Save")}
													</Button>
												</Grid>
											</Grid>
										</form>
									)}
								</Form>
							</DialogContent>
						</MuiDialog>

						<>
							<Typography variant="h2" mb={2}>
								{t("Your Details")}
							</Typography>
							<Divider />
							<Grid
								item
								container
								direction="row"
								spacing={2}
								mt={0}
							>
								<Grid item>
									<Avatar
										alt="Name"
										src="/assets/class-guru.svg"
										sx={{
											width: 128,
											height: 128,
											border: "1px solid rgba(0,0,0,0.15)",
										}}
									/>
								</Grid>
								<Grid item>
									<Entry variant="body2" mt={2}>
										{session.user?.username}
									</Entry>
									<Entry variant="body2">
										{session.user?.email}
									</Entry>
									<Entry variant="body2" mb={6}>
										{session.user?.firstName || "foobar"}{" "}
										{session.user?.lastName}
									</Entry>
									<Button
										variant="contained"
										color="primary"
										disableElevation
										mt={2}
										onClick={handleEditUser}
									>
										{t("Edit")}
									</Button>
								</Grid>
							</Grid>
						</>
					</StyledCard>
				</Grid>
				<Grid item container>
					<StyledCard variant="outlined">
						<Typography variant="h2" mb={2}>
							{t("Change Password")}
						</Typography>
						<Divider />

						<Form onSubmit={handleChangePassword}>
							{({ handleSubmit, values }) => (
								<form onSubmit={handleSubmit}>
									<FormField
										name="currentPassword"
										label={t("Current Password")}
										fullWidth
										type="password"
									/>
									<FormField
										name="newPassword"
										label={t("New Password")}
										fullWidth
										type="password"
									/>
									<FormField
										name="confirmPassword"
										label={t("Confirm Password")}
										fullWidth
										type="password"
									/>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disableElevation
										mt={2}
										// onClick={handleChangePassword}
										disabled={isLoading}
									>
										{t("Save new password")}
									</Button>
								</form>
							)}
						</Form>
					</StyledCard>
				</Grid>
				<Grid item container>
					<StyledCard variant="outlined">
						{editStates.editRealm ? (
							<Form
								onSubmit={handleSaveRealm}
								initialValues={initialRealmValues}
							>
								{({ handleSubmit }) => (
									<form onSubmit={handleSubmit}>
										<Typography variant="h2" mb={2}>
											{t("Invoice Address")}
										</Typography>
										<Divider />
										<FormField
											name="companyName"
											label={t("Company Name")}
										/>
										<Entry variant="body2">
											<FormField
												name="firstName"
												label={t("First Name")}
											/>
											<FormField
												name="lastName"
												label={t("Last Name")}
											/>
										</Entry>
										<Entry variant="body2">
											<FormField
												name="streetName"
												label={t("Street Name")}
											/>
											<FormField
												name="streetNumber"
												label={t("Street Number")}
											/>
										</Entry>
										<Entry variant="body2">
											<FormField
												name="postalCode"
												label={t("Postal Code")}
											/>{" "}
											<FormField
												name="city"
												label={t("City")}
											/>
										</Entry>
										<Entry variant="body2" mb={3}>
											<FormField
												name="country"
												label={t("Country")}
											/>
										</Entry>
										<Entry variant="body2">
											<FormField
												name="vatIdentifier"
												label={t("Vat Identifier")}
											/>
										</Entry>
										<Entry variant="body2">
											<FormField
												name="website"
												label={t("Website")}
											/>
										</Entry>
										<Button
											variant="contained"
											color="primary"
											disableElevation
											mt={2}
											// onClick={handleSaveRealm}
											type="submit"
										>
											{t("Save")}
										</Button>
									</form>
								)}
							</Form>
						) : (
							<>
								<Typography variant="h2" mb={2}>
									{t("Invoice Address")}
								</Typography>
								<Divider />
								<Entry variant="body2" mt={2}>
									{session.realm?.companyName}
								</Entry>
								<Entry variant="body2">
									{session.realm?.firstName}
									{session.realm?.lastName}
								</Entry>
								<Entry variant="body2">
									{session.realm?.streetName}
									{session.realm?.streetNumber}
								</Entry>
								<Entry variant="body2">
									{session.realm?.postalCode}
									{session.realm?.city}
								</Entry>
								<Entry variant="body2" mb={3}>
									{session.realm?.country}
								</Entry>
								<Entry variant="body2">
									{session.realm?.vatIdentifier}
								</Entry>
								<Entry variant="body2">
									{session.realm?.website}
								</Entry>
								<Button
									variant="contained"
									color="primary"
									disableElevation
									mt={2}
									onClick={handleEditRealm}
								>
									{t("Edit")}
								</Button>
							</>
						)}
					</StyledCard>
				</Grid>
			</Grid>
			{/* <pre>{JSON.stringify(session, null, 2)}</pre> */}
		</PageContainer>
	);
};

export default Profile;
