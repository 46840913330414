import React, { useState } from "react";
import { SlotInfo } from "react-big-calendar";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "components/dialogs/DialogTitle";
import DialogContent from "components/dialogs/DialogContent";
import DialogActions from "components/dialogs/DialogActions";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Fa } from "../../../../styled/muiComponents";
import Divider from '@mui/material/Divider';
import {
	Autocomplete,
	CircularProgress,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";
import {
	useCreateCalendarEntryMutation,
	useGetSlideCollectionsQuery,
	useGetStudentsQuery,
} from "rtk/api";
import { Field, Form } from "react-final-form";
import { CalendarEntryCreate, Student } from "rtk/types";
import { useTranslation } from "react-i18next";
import { LoadingBubble } from "routes/app/Class/stageview/constants/styledComponents";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable
			handle="#draggable-dialog-title"
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper
				elevation={0}
				{...props}
				style={{ outline: "10px solid rgba(255,255,255,0.25)" }}
			/>
		</Draggable>
	);
}

interface Props {
	onClose: () => void;
	open: boolean;
	slotInfo?: SlotInfo;
}

const CreateCalendarEntryDialog: React.FC<Props> = ({
	open,
	onClose,
	slotInfo,
}) => {
	const { t } = useTranslation();
	const [studentsFilterString, setStudentsFilterString] = useState<
		string | null
	>(null);
	const [createCalendarEntry, { isLoading }] =
		useCreateCalendarEntryMutation();
	const { data: students, isLoading: isLoadingStudents } =
		useGetStudentsQuery(studentsFilterString);
	const { data: slideCollections } = useGetSlideCollectionsQuery();
	const [tabValue, setTabValue] = useState<number>(0);

	const onSubmit = async (data: {
		name: string;
		description: string;
		student: Student | null;
		studentEmail?: string;
		slideCollectionOption?: { id: number; label: string };
	}) => {
		if (!slotInfo) return;

		let calendarEntryCreate: CalendarEntryCreate = {
			name: data.name,
			description: data.description,
			visibility: "PUBLIC",
			studentId: data.student ? Number(data.student.id) : undefined,
			studentEmail: data.studentEmail,
			slideCollectionId: data.slideCollectionOption
				? Number(data.slideCollectionOption.id)
				: undefined,
			startAt: slotInfo.slots[0],
			endAt: slotInfo.slots[slotInfo.slots.length - 1],
		};

		await createCalendarEntry(calendarEntryCreate);
		onClose();
	};

	const handleChangeTabs = (
		event: React.SyntheticEvent,
		newValue: number
	) => {
		if (newValue !== null) {
			setTabValue(newValue);
		  }
		
	};

	const handleCancel = () => {
		onClose();
	};

	const validate = (values: {
		name: string;
		description: string;
		student: Student | null;
		studentEmail?: string;
		slideCollectionOption: { id: number; label: string };
	}) => {
		const errors: { [key: string]: string } = {};

		if (!values.name) {
			errors.name = "Required";
		}

		if (!values.student && !values.studentEmail) {
			errors.student = "Either student or student email is required";
			errors.studentEmail = "Either student or student email is required";
		}

		// if (!values.slideCollectionOption) {
		// 	errors.slideCollectionOption = "Required";
		// }

		return errors;
	};

	const a11yProps = (index: number) => {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `tabpanel-${index}`,
		};
	};

	if (!slotInfo) return null;

	if (isLoadingStudents) return <LoadingBubble />;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="sm"
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<DialogTitle id="draggable-dialog-title" onClose={onClose}>
				{t("Create a new Lesson")}
			</DialogTitle>

			<DialogContent>
				<Form
					onSubmit={onSubmit}
					validate={validate}
					render={({ handleSubmit, values }) => (
						<form
							id="create-calendar-entry-form"
							onSubmit={handleSubmit}
						>
							
							<Grid container item alignItems="center" direction="row" mb={3}>
								<ButtonGroup variant="outlined">
									<Button
										variant="outlined"
										onClick={() => {
											// Handle date change logic here
											const newDate = prompt("Enter new date (YYYY-MM-DD):", new Date(slotInfo.slots[0]).toISOString().split('T')[0]);
											if (newDate) {
												const newStartDate = new Date(newDate + 'T' + new Date(slotInfo.slots[0]).toISOString().split('T')[1]);
												const newEndDate = new Date(newDate + 'T' + new Date(slotInfo.slots[slotInfo.slots.length - 1]).toISOString().split('T')[1]);
												slotInfo.slots[0] = newStartDate;
												slotInfo.slots[slotInfo.slots.length - 1] = newEndDate;
												document.getElementById('calendar-dialog-date-button')!.innerHTML = new Date(slotInfo.slots[0]).toLocaleDateString();
											}
										}}
										id="calendar-dialog-date-button"
									>
										<Fa icon={["fal", "calendar-days"]} fixedWidth style={{marginRight: "8px"}} /> {new Date(slotInfo.slots[0]).toLocaleDateString()}
									</Button>
									<Button
										variant="outlined"
										onClick={() => {
											// Handle time change logic here
											const newTime = prompt("Enter new start time (HH:MM):", new Date(slotInfo.slots[0]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
											if (newTime) {
												const [hours, minutes] = newTime.split(':');
												const newStartDate = new Date(slotInfo.slots[0]);
												newStartDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));
												slotInfo.slots[0] = newStartDate;
												document.getElementById('calendar-dialog-time-button')!.innerHTML = new Date(slotInfo.slots[0]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
											}
										}}
										id="calendar-dialog-time-button"
									>
										<Fa icon={["fal", "clock"]} fixedWidth style={{marginRight: "8px"}} /> {new Date(slotInfo.slots[0]).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
									</Button>
									<Button
										variant="outlined"
										onClick={() => {
											// Handle duration change logic here
											const durationOptions = [25, 45, 90];
											const newDuration = prompt("Enter new duration (minutes):", durationOptions.join(', '));
											if (newDuration && durationOptions.includes(parseInt(newDuration, 10))) {
												const newEndDate = new Date(slotInfo.slots[0]);
												newEndDate.setMinutes(newEndDate.getMinutes() + parseInt(newDuration, 10));
												slotInfo.slots[slotInfo.slots.length - 1] = newEndDate;
											}
										}}
									>
										{/* ToDo - Add Duration Chooser - Standard: 25, 30, 45, 50, 60, 90 */}
										<Fa icon={["fal", "timer"]} fixedWidth style={{marginRight: "8px"}} /> {Math.round((slotInfo.slots[slotInfo.slots.length - 1].getTime() - slotInfo.slots[0].getTime()) / 60000)} min
									</Button> 
								</ButtonGroup>
							</Grid>
							<Grid container direction="column" gap={3}>
								<Grid item>
									<Field name="name">
										{(props) => (
											<TextField
												required
												label="Name"
												name={props.input.name}
												value={props.input.value}
												onChange={props.input.onChange}
												fullWidth
												error={
													props.meta.touched &&
													Boolean(props.meta.error)
												}
												helperText={
													props.meta.touched &&
													props.meta.error
												}
											/>
										)}
									</Field>
								</Grid>
								<Grid item>
									<Field name="description">
										{(props) => (
											<TextField
												label="Description"
												name={props.input.name}
												value={props.input.value}
												onChange={props.input.onChange}
												fullWidth
												multiline={true}
												rows={4}
											/>
										)}
									</Field>
								</Grid>
								<Field name="slideCollectionOption">
										{(props) => (
											<Autocomplete
												disablePortal
												options={
													slideCollections
														? slideCollections
														: []
												}
												onChange={(a, b) => {
													props.input.onChange(b);
												}}
												getOptionLabel={(option) =>
													option.name
												}
												value={
													props.input.value || null
												}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Slide Collection"
														error={
															props.meta
																.touched &&
															Boolean(
																props.meta.error
															)
														}
														helperText={
															props.meta
																.touched &&
															props.meta.error
														}
													/>
												)}
											/>
										)}
									</Field>
									
									<Divider>{t("ASSIGN STUDENT")}</Divider>
								<Grid item>
									<ToggleButtonGroup
										value={tabValue}
										exclusive
										onChange={handleChangeTabs}
										aria-label="student selection"
										size="small"
									>
										<ToggleButton value={0} aria-label="old student" style={{paddingLeft: "12px", paddingRight: "12px"}}>
											<Fa icon={["fal", "graduation-cap"]} fixedWidth style={{marginRight: "8px"}} /> {t("Select Student")}
										</ToggleButton>
										<ToggleButton value={1} aria-label="new student" style={{paddingLeft: "12px", paddingRight: "12px"}}>
											<Fa icon={["fal", "paper-plane"]} fixedWidth style={{marginRight: "8px"}} /> {t("Invite Student")}
										</ToggleButton>
									</ToggleButtonGroup>
									<div
										hidden={tabValue !== 0}
										id={`tabpanel-0`}
									>
										<p>
											{t(
												"Pick a student you have worked with before"
											)}
										</p>
										<Field name="student">
											{(props) => (
												<Autocomplete
													disablePortal
													options={
														students ? students : []
													}
													disabled={
														!students ||
														students.length === 0
													}
													onChange={(a, b) => {
														props.input.onChange(b);
													}}
													getOptionLabel={(option) =>
														`${option.username} (${option.email})`
													}
													value={
														props.input.value ||
														null
													}
													renderInput={(params) => (
														<TextField
															{...params}
															onChange={(e) =>
																setStudentsFilterString(
																	e.target
																		.value
																)
															}
															label={
																!students
																	? t(
																			"No Students Available"
																	  )
																	: "Student"
															}
															error={
																props.meta
																	.touched &&
																Boolean(
																	props.meta
																		.error
																)
															}
															helperText={
																props.meta
																	.touched &&
																props.meta.error
															}
															required={
																!values.studentEmail
															}
														/>
													)}
												/>
											)}
										</Field>
									</div>
									<div
										hidden={tabValue !== 1}
										id={`tabpanel-1}`}
									>
										<p>
											{t(
												"Or invite a student using their email"
											)}
										</p>
										<Field name="studentEmail">
											{(props) => (
												<TextField
													type="email"
													label={
														"Student Email" +
														(values.student
															? " (known student is already selected)"
															: "")
													}
													name={props.input.name}
													value={
														values.student
															? ""
															: props.input.value
													}
													onChange={
														props.input.onChange
													}
													fullWidth
													disabled={Boolean(
														values.student
													)}
													error={
														props.meta.touched &&
														Boolean(
															props.meta.error
														)
													}
													helperText={
														props.meta.touched &&
														props.meta.error
													}
													required={!values.student}
												/>
											)}
										</Field>
									</div>
								</Grid>
								<Grid item>
									
								</Grid>
							</Grid>
						</form>
					)}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					disableElevation
					color="error"
					onClick={handleCancel}
				>
					Cancel
				</Button>
				<Button
					type="submit"
					form="create-calendar-entry-form"
					variant="contained"
					disableElevation
					color="success"
					disabled={isLoading}
				>
					{isLoading && (
						<CircularProgress size={24} color="inherit" />
					)}
					{t("Create Lesson")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateCalendarEntryDialog;
