import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#2B2240",
			dark: "#171223",
			light: "#413a5c",
			contrastText: "#dcdae6",
		},
		secondary: {
			//main: '#d53237'
			main: "#ffac2b",
		},
		success: {
			main: "#83ac1b",
			contrastText: "white",
		},
		white: {
			main: "rgba(255, 255, 255, 0.9)",
			dark: "#ffffff",
			light: "rgba(255, 255, 255, 0.15)",
			contrastText: "rgba(0, 0, 0, 0.85)",
		},
	},

	typography: {
		h1: {
			fontSize: "2.25rem",
			fontWeight: "700",
			fontFamily: "'como', 'roboto', sans-serif",
		},
		h2: {
			fontSize: "1.4rem",
			fontWeight: "700",
			fontFamily: "'como', 'roboto', sans-serif",
		},
		h3: {
			fontSize: "1.3rem",
			fontWeight: "500",
			fontFamily: "'como', 'roboto', sans-serif",
		},
		h4: {
			fontSize: "1.25rem",
			fontWeight: "500",
			fontFamily: "'como', 'roboto', sans-serif",
		},
		h5: {
			fontSize: "1.2rem",
			fontWeight: "400",
			fontFamily: "'como', 'roboto', sans-serif",
		},
		h6: {
			fontSize: "1.1rem",
			fontWeight: "400",
			fontFamily: "'como', 'roboto', sans-serif",
		},
		body1: {
			fontWeight: "400",
			fontFamily: "'roboto', sans-serif",
		},
		body2: {
			fontWeight: "400",
			fontFamily: "'como', 'roboto', sans-serif",
		},
		button: {
			fontSize: "1rem",
			fontWeight: "400",
			fontFamily: "'roboto', sans-serif",
		},
	},
});

export { theme };
