import useWebSocketOriginal, { Options } from "react-use-websocket"

const useWebSocket = (url: string | (() => string | Promise<string>) | null, options?: Options) => {
    const original = useWebSocketOriginal(url, options);

    const sendJsonMessage = (msg: any) => {
        original.sendMessage(JSON.stringify(msg))
    }

    return {
        ...original,
        sendJsonMessage
    }
}

export default useWebSocket;