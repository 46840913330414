import React, { useState } from "react";
import "./fileselect.css";
import Lottie from "lottie-react";
import uploadAnimation from "../../assets/lottie/upload.json";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
interface FileSelectProps {
	accept: string; // Accepted file types (e.g., 'image/*', '.pdf', '.doc')
	onChange: (files: File[]) => void; // onChange handler that returns the array of files
}

const FileSelect: React.FC<FileSelectProps> = ({ accept, onChange }) => {
	const [files, setFiles] = useState<File[]>([]);
	const [isDragOver, setIsDragOver] = useState(false);
	const [fileSelected, setFileSelected] = useState<boolean>(false);
	const { t } = useTranslation();
	const theme = useTheme();

	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFiles = event.target.files;
		if (selectedFiles) {
			const filesArray = Array.from(selectedFiles);
			setFiles(filesArray);
			onChange(filesArray);
		}
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDragOver(true);
	};

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDragOver(false);
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		setFileSelected(true);
		console.log("fileSelected: " + fileSelected);
		event.preventDefault();

		const droppedFiles = event.dataTransfer.files;
		if (droppedFiles) {
			const filesArray = Array.from(droppedFiles);
			setFiles(filesArray);
			onChange(filesArray);
		}
		setIsDragOver(false);
	};

	return (
		<div
			className={`file-select ${isDragOver ? "drag-over" : ""}`}
			onDragOver={handleDragOver}
			onDragLeave={handleDragLeave}
			onDrop={handleDrop}
			style={
				isDragOver || fileSelected || files.length > 0
					? { border: "2px solid #ffac2b" }
					: { border: "2px dashed #ffac2b" }
			}
		>
			{" "}
			<div className="drop-zone">
				<div className="drop-zone-content">
					<Button
						variant="text"
						component="label"
						className="drop-zone-button"
						style={
							fileSelected || files.length > 0
								? { filter: "grayscale(0)" }
								: { filter: "grayscale(1)" }
						}
					>
						<Lottie
							animationData={uploadAnimation}
							loop={true}
							style={{
								maxWidth: "200px",
								display: "flex",
								alignItems: "center",
								marginTop: "-30px",
								marginBottom: "-20px",
							}}
						/>
						{fileSelected || files.length > 0 ? (
							<h2 style={{ textAlign: "center" }}>
								{t("Selected file:")}
							</h2>
						) : (
							<>
								<h2 style={{ textAlign: "center" }}>
									{t("Drop file here")}
								</h2>
								<span
									style={{
										textAlign: "center",
										display: "block",
										color: "rgba(255, 255, 255, 0.9)",
									}}
								>
									{t("or click to select")}
								</span>
							</>
						)}

						<input
							type="file"
							accept={accept}
							onChange={handleFileSelect}
							hidden
						/>
						<div
							className="file-names"
							style={{
								color: theme.palette.primary.main,
								marginTop: "10px",
							}}
						>
							{files.map((file, index) => (
								<div
									key={index}
									className="file-name"
									style={{
										backgroundColor: "#ffffff",
									}}
								>
									{file.name}
								</div>
							))}
						</div>
					</Button>
				</div>
			</div>
		</div>
	);
};

export default FileSelect;
