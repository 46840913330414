import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Realm, User } from "store/reducers/session";

import {
	RegisterCredentials,
	LoginCredentials,
	SetPasswordCredentials,
	ResetPasswordCredentials,
	SlideCollection,
	SlideCollectionCreate,
	CalendarEntry,
	CalendarEntryCreate,
	Lesson,
	ClassroomInfo,
	Student,
	LessonInvite,
	Folder,
	FolderCreate,
	ChangePassword,
	Course,
	PaginationParams,
	PaginationResponse,
	PaginatedResponse,
	CourseCreate,
	GetLesson,
} from "./types";
interface UnsplashPhoto {
	id: string;
	alt_description: string;
	urls: {
		small: string;
		regular: string;
	};
	// Add other relevant fields here
}

// interface UnsplashPhoto {
// 	id: string;
// 	description: string | null;
// 	urls: {
// 	  regular: string;
// 	};
// 	// Other properties...
//   }

interface UnsplashSearchResponse {
	results: UnsplashPhoto[];
	// Other properties...
}
// Define a service using a base URL and expected endpoints
const api = createApi({
	reducerPath: "api",
	baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
	tagTypes: ["session", "calendar_entries", "folders", "courses"],
	endpoints: (builder) => ({
		searchUnsplash: builder.query<
			UnsplashSearchResponse,
			{ query: string; page: number }
		>({
			query: ({ query, page }) => ({
				url: `https://api.unsplash.com/search/photos`,
				method: "GET",
				params: {
					query: query, // Pass the search query as a parameter
					per_page: 5,
					orientation: "landscape",
					page: page,
				},
				headers: {
					Authorization: `Client-ID BzRWN_qZEhYWGyGn7wo5tbhTiYpLWGOGUKJddzZzjVI`, // Replace with your Unsplash API key
				},
			}),
		}),
		register: builder.mutation<
			{ token: string } | void,
			RegisterCredentials
		>({
			query: (credentials: RegisterCredentials) => ({
				url: "/register",
				method: "POST",
				body: credentials,
			}),
		}),
		login: builder.mutation<string, LoginCredentials>({
			query: (credentials: LoginCredentials) => ({
				url: "/login",
				method: "POST",
				body: credentials,
			}),
			invalidatesTags: ["session"],
		}),
		setPassword: builder.mutation<string, SetPasswordCredentials>({
			query: (credentials: SetPasswordCredentials) => ({
				url: "/set_password",
				method: "POST",
				body: credentials,
			}),
		}),
		resetPassword: builder.mutation<string, ResetPasswordCredentials>({
			query: (credentials: ResetPasswordCredentials) => ({
        url: "/reset_password",
				method: "POST",
				body: credentials,
			}),
			transformErrorResponse: (e) => e.data,
		}),
		changePassword: builder.mutation<any, ChangePassword>({
			query: (passwords: ChangePassword) => ({
				url: "users/me/change_password",
				method: "POST",
				body: passwords,
			}),
		}),
		isValidEmailActivationToken: builder.mutation<string, string>({
			query: (token: string) => ({
				url: `/is_valid_email_activation_token/${token}`,
				method: "POST",
			}),
		}),
		getSessionInfo: builder.query<{ user: User; realm: Realm }, void>({
			query: () => ({
				url: `/session_info`,
			}),
			providesTags: ["session"],
		}),
		updateSessionInfo: builder.mutation<
			{ user: User; realm: Realm },
			{ user: User; realm: Realm }
		>({
			query: (body) => ({
				url: `/session_info`,
				method: "PUT",
				body,
			}),
			invalidatesTags: ["session"],
		}),
		logout: builder.mutation<void, void>({
			query: () => ({
				url: `/logout`,
				method: "POST",
			}),
			invalidatesTags: ["session"],
		}),
		jwtToken: builder.query<{ token: string }, void>({
			query: () => ({
				url: `/jwt`,
			}),
		}),
		uploadImage: builder.mutation({
			query: (file) => ({
				url: "/images/create", //TODO why create? what did I drink?
				method: "POST",
				body: file,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}),
		}),
		createSlideCollection: builder.mutation<
			SlideCollection,
			SlideCollectionCreate
		>({
			query: (slideCollection) => ({
				url: "/slide_collections",
				method: "POST",
				body: slideCollection,
			}),
		}),
		getSlideCollections: builder.query<SlideCollection[], void>({
			query: () => ({
				url: "/slide_collections",
				method: "GET",
			}),
		}),
		getSlideCollection: builder.query<SlideCollection, number>({
			query: (id) => ({
				url: `/slide_collections/${id}`,
				method: "GET",
			}),
		}),
		updateSlideCollection: builder.mutation<
			SlideCollection,
			SlideCollection
		>({
			query: (slideCollection: SlideCollection) => ({
				url: `/slide_collections/${slideCollection.id}`,
				method: "PUT",
				body: slideCollection,
			}),
		}),
		deleteSlideCollection: builder.mutation<void, number>({
			query: (id) => ({
				url: `/slide_collections/${id}`,
				method: "DELETE",
			}),
		}),
		createCalendarEntry: builder.mutation<
			CalendarEntry,
			CalendarEntryCreate
		>({
			query: (calendarEntry) => ({
				url: "/lessons",
				method: "POST",
				body: calendarEntry,
			}),
			invalidatesTags: ["calendar_entries"],
		}),
		getLesson: builder.query<Lesson, number>({
			query: (id) => ({
				url: `/lessons/${id}`,
				method: "GET",
			}),
		}),
		getLessons: builder.query<Lesson[], GetLesson>({
			query: ({ studentId = null, from, to }) => ({
				url: studentId
					? `students/${studentId}/lessons?from=${from}&to=${to}`
					: "/lessons?from=" + from + "&to=" + to,
				method: "GET",
			}),
			providesTags: ["calendar_entries"],
		}),
		getClassroomInfo: builder.query<ClassroomInfo, string>({
			query: (uuid) => ({
				url: `/classrooms/${uuid}/info`,
				method: "GET",
			}),
		}),
		getStudents: builder.query<Student[], string | null>({
			query: (filterString) => ({
				url: filterString
					? `students?filter_string=${filterString}`
					: "students",
				method: "GET",
			}),
		}),
		getStudent: builder.query<Student, string>({
			query: (id) => ({
				url: `students/${id}`,
				method: "GET",
			}),
		}),
		getFolder: builder.query<Folder, { id: number }>({
			query: ({ id }) => ({
				url: `/folders/${id}`,
				method: "GET",
			}),
			providesTags: ["folders"],
		}),
		getFolders: builder.query<Folder[], void>({
			query: () => ({
				url: `/folders`,
				method: "GET",
			}),
			providesTags: ["folders"],
		}),
		createFolder: builder.mutation<Folder, FolderCreate>({
			query: (folder) => ({
				url: "/folders",
				method: "POST",
				body: folder,
			}),
			invalidatesTags: ["folders"],
		}),
		isValidLessonInvitationToken: builder.mutation<void, string>({
			query: (token) => ({
				url: `/is_valid_lesson_invitation_token/${token}`,
				method: "POST",
			}),
		}),
		getLessonInvite: builder.query<LessonInvite, string>({
			query: (token) => ({
				url: `/lesson_invites/${token}`,
				method: "GET",
			}),
		}),
		lessonInviteRespond: builder.mutation<
			void,
			{ token: string; response: string }
		>({
			query: ({ token, response }) => ({
				url: `/lesson_invites/${token}/${response}`,
				method: "POST",
			}),
		}),
		getCourse: builder.query<Course, { id: number }>({
			query: ({ id }) => ({
				url: `/courses/${id}`,
				method: "GET",
			}),
			providesTags: ["courses"],
		}),
		getCourses: builder.query<PaginatedResponse<Course>, PaginationParams>({
			query: ({ pageSize, page, order, orderBy }) => ({
				url: `/courses?pageSize=${pageSize}&page=${page}&orderBy=${orderBy}&order=${order}`,
				method: "GET",
				providesTags: ["courses"],
			}),
		}),
		createCourse: builder.mutation<Course, CourseCreate>({
			query: (course) => ({
				url: "/courses",
				method: "POST",
				body: course,
			}),
			invalidatesTags: ["courses"],
		}),
	}),
});

export const {
	useRegisterMutation,
	useLoginMutation,
	useSetPasswordMutation,
	useResetPasswordMutation,
	useChangePasswordMutation,
	useIsValidEmailActivationTokenMutation,
	useGetSessionInfoQuery,
	useUpdateSessionInfoMutation,
	useLogoutMutation,
	useJwtTokenQuery,
	useUploadImageMutation,
	useGetSlideCollectionQuery,
	useGetSlideCollectionsQuery,
	useCreateSlideCollectionMutation,
	useUpdateSlideCollectionMutation,
	useDeleteSlideCollectionMutation,
	useCreateCalendarEntryMutation,
	useGetLessonsQuery,
	useGetClassroomInfoQuery,
	useGetStudentsQuery,
	useGetStudentQuery,
	useIsValidLessonInvitationTokenMutation,
	useGetLessonInviteQuery,
	useLessonInviteRespondMutation,
	useGetLessonQuery,
	useCreateFolderMutation,
	useGetFolderQuery,
	useGetFoldersQuery,
	useSearchUnsplashQuery,
	useGetCoursesQuery,
	useCreateCourseMutation,
	useGetCourseQuery,
} = api;
export default api;
