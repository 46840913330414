import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import SessionReducer from "./reducers/session";
import chatReducer from "./reducers/chat/reducer";
import classReducer from "./reducers/class/class";
import stageReducer from "./reducers/class/stages";
import layerReducer from "./reducers/class/layers";
import itemReducer from "./reducers/class/items";
import appReducer from "./reducers/app/reducer";
import api from "rtk/api";
import classWsMiddleware from "store/middleware/ws";
import timerMiddleware from "store/middleware/timer";

export const store = configureStore({
	reducer: {
		session: SessionReducer,
		chat: chatReducer,
		class: classReducer,
		stages: stageReducer,
		layers: layerReducer,
		items: itemReducer,
		app: appReducer,
		[api.reducerPath]: api.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			api.middleware,
			classWsMiddleware,
			timerMiddleware,
		]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
