import { ChatMessage } from "./reducer";
import styles from './chat.module.css'
import { ReadyState } from "react-use-websocket";
import useWebSocket from "hooks/useWebSocker";
import { useEffect } from "react";
import { useState } from "react";

type Props = {
    roomId: String
}

const ReadyStateIndicator = ({ readyState }: { readyState: ReadyState }) => {
    let text;
    switch (readyState) {
        case ReadyState.CLOSED: text = "Connection is closed"
            break;
        case ReadyState.CLOSING: text = "Disconnecting"
            break;
        case ReadyState.CONNECTING: text = "Connecting to chat room..."
            break;
        case ReadyState.OPEN: text = "Connection active"
            break;
    }
    return <span className={[styles.readystateindicator, readyState === ReadyState.OPEN ? styles.active : null].filter(e => e != null).join(" ")}> {text} </span>
}

const Chat = ({ roomId }: Props) => {
    const [messageHistory, setMessageHistory] = useState<ChatMessage[]>([]);
    const { sendJsonMessage, sendMessage, readyState } = useWebSocket(`${process.env.REACT_APP_SOCKET_URL}/ws` || "REACT_APP_SOCKER_URL env missing!", {
        onMessage: (msg) => {
            console.log(msg)
            setMessageHistory((prev: ChatMessage[]) => [...prev, msg.data])
        }
    });

    useEffect(() => {
        sendJsonMessage({ kind: "joinroom", payload: { roomId: roomId, username: `Kevin` } })
    }, [roomId, sendJsonMessage])

    console.log(messageHistory, readyState)


    const handleSubmit = (e: any) => {
        e.preventDefault()
        let message = e.target.elements.message.value
        sendMessage(JSON.stringify({
            kind: "send",
            payload: {
                roomId,
                message
            }
        }))
    }

    return (
        <div className={styles.outer}>
            <h3> Chat </h3>
            <div className={styles.chatwindow}>
                <div>
                    {messageHistory.map(message => (
                        <div>
                            <span> {JSON.stringify(message)} </span>
                        </div>
                    ))}
                </div>
                <form className={styles.footer} onSubmit={handleSubmit}>
                    <input name="message" defaultValue="" placeholder="type your message here" />
                    <button type="submit"> Send </button>
                </form>
            </div>
            <ReadyStateIndicator readyState={readyState} />
        </div>
    )
}

export default Chat;