import * as pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export async function pdfToDataUrls(file: File): Promise<Promise<string>[]> {
	const fileDataUrl = await readFileAsDataUrl(file);

	const loadingTask = pdfjs.getDocument(fileDataUrl);
	const pdf = await loadingTask.promise;
	const totalNumPages = pdf.numPages;

	const pagePromises: Promise<string>[] = [];
	for (let i = 1; i <= totalNumPages; i++) {
		const pagePromise = convertPageToDataUrl(pdf, i);
		pagePromises.push(pagePromise);
	}

	return Promise.resolve(pagePromises);
}

export async function numPagesInPdf(file: File): Promise<number> {
	const fileDataUrl = await readFileAsDataUrl(file);

	const loadingTask = pdfjs.getDocument(fileDataUrl);
	const pdf = await loadingTask.promise;
	return pdf.numPages;
}

function convertPageToDataUrl(
	pdf: pdfjs.PDFDocumentProxy,
	pageNum: number
): Promise<string> {
	return new Promise<string>((resolve, reject) => {
		pdf.getPage(pageNum)
			.then((page) => {
				const rotation = page.rotate;
				const viewport = page.getViewport({ scale: 1, rotation });
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");

				if (rotation % 180 === 90) {
					// Swap width and height for landscape mode
					canvas.width = viewport.height;
					canvas.height = viewport.width;
				} else {
					canvas.width = viewport.width;
					canvas.height = viewport.height;
				}

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};
				const renderTask = page.render(renderContext as any);
				renderTask.promise
					.then(() => {
						resolve(canvas.toDataURL());
					})
					.catch((error) => {
						reject(error);
					});
			})
			.catch((error) => {
				reject(error);
			});
	});
}

function readFileAsDataUrl(file: File): Promise<string> {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result as string);
		reader.onerror = () => reject(reader.error);
		reader.readAsDataURL(file);
	});
}
