import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useTheme } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
	padding: `${theme.spacing(1)} ${theme.spacing(1)} !important`,
	width: "100%",
	height: "100%",
	boxSizing: "border-box",
}));
const StyledPerfectScrollbar = styled(PerfectScrollbar)(({ theme }) => ({
	/* ToDo condition for student (false) and teacher (true)*/
	height: false ? "100vh" : "100%",
	paddingLeft: false ? `${theme.spacing(1)}` : 0,
	paddingRight: false ? `${theme.spacing(1)}` : 0,
	boxSizing: "border-box",
	maxHeight: "100%",
	width: "100%",
}));

export const PageContainer = ({
	children,
	maxWidth,
}: {
	children: any;
	maxWidth: any;
}) => {
	const theme = useTheme();
	return (
		<Container
			maxWidth={false}
			style={{
				padding: `${theme.spacing(1)}`,
				boxSizing: "border-box",
				/* ToDo condition for student (false) and teacher (true)*/
				height: false ? "100vh" : "calc(100vh - 71px)",
				maxHeight: "100%",
				width: "100%",
				overflow: "hidden",
			}}
		>
			<StyledPerfectScrollbar>
				<StyledContainer maxWidth={maxWidth}>
					{children}
				</StyledContainer>
			</StyledPerfectScrollbar>
		</Container>
	);
};

export default PageContainer;
