import { Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import FileSelect from "components/fileselect/FileSelect";
import { useState } from "react";
import {
	useCreateSlideCollectionMutation,
	useUploadImageMutation,
} from "rtk/api";
import { numPagesInPdf, pdfToDataUrls } from "./pdf";
import { v4 as uuid } from "uuid";
import { ItemType } from "store/reducers/class/items";
import { RawStage } from "store/reducers/class/stages";
import { SlideCollectionCreate } from "rtk/types";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import { Fa } from "../../../../../styled/muiComponents";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Lottie from "lottie-react";
import extractAnimation from "assets/lottie/extract-animation.json";
import { useNavigate } from "react-router-dom";

export function imageToStage(
	id: number,
	width: number = 910,
	height: number = 683
): RawStage {
	return {
		id: uuid(),
		width,
		height,
		layers: [
			{
				id: uuid(),
				items: [
					{
						id: uuid(),
						type: ItemType.Image,
						x: 0,
						y: 0,
						width,
						height,
						src: `/api/images/${id}/redirect`,
					},
				],
			},
		],
	};
}

interface Image {
	src: string | null;
	isLoading: boolean;
	isGenerating: boolean;
}

interface Props {
	folderId?: number;
}

const SlideCollectionFromPdf = ({ folderId }: Props) => {
	console.log(folderId);
	const [files, setFiles] = useState<File[]>([]);
	const [images, setImages] = useState<Image[]>([]);
	const [isGenerating, setIsGenerating] = useState(false);
	const navigate = useNavigate();
	const [uploadImage, { isLoading, isError, error: uploadImageError }] =
		useUploadImageMutation();
	const [createSlideCollection, { error: createSlideCollectionError }] =
		useCreateSlideCollectionMutation();
	const theme = useTheme();
	const { t } = useTranslation();
	const [activeStep, setActiveStep] = useState(0);
	const [openPreview, setOpenPreview] = useState(false);
	const [progress, setProgress] = useState(0);

	const H1 = styled("h1")(({ theme }) => ({
		textAlign: "center",
		color: "rgba(255,255,255,0.9)",
		maxWidth: "400px",
		marginBottom: "32px",
	}));

	const handleGenerateSlideCollection = async () => {
		console.log("handling upload", files);
		if (files === null || files.length === 0) return;
		setActiveStep(1);
		setIsGenerating(true);

		let images: Image[] = [];

		try {
			const numPages = await numPagesInPdf(files[0]);
			images = Array(numPages).fill({
				url: null,
				isLoading: false,
				isGenerating: true,
			});
			const dataUrls = await pdfToDataUrls(files[0]);

			for (let idx = 0; idx < dataUrls.length; idx++) {
				const url = await dataUrls[idx];

				images[idx] = {
					src: url,
					isLoading: false,
					isGenerating: false,
				};
			}
		} catch (error) {
			console.error("ups", error);
		} finally {
			setIsGenerating(false);
		}
		setActiveStep(2);
		setImages(images);
		handleImagesToSlideCollection(images);
	};

	window.addEventListener("beforeunload", (event) => {
		if (isGenerating) {
			event.preventDefault();
			event.returnValue = "";
		}
	});

	const handleImagesToSlideCollection = async (images: Image[]) => {
		setActiveStep(2);
		const slideCollection: SlideCollectionCreate = {
			name: files[0].name,
			slides: [],
			folderId: folderId ? folderId : null,
		};
		let validImages = images.filter((image) => image.src !== null);
		try {
			for (let i = 0; i < validImages.length; i++) {
				const dataUrl = validImages[i].src as string;

				const img = new Image();
				img.src = dataUrl;

				await new Promise((resolve) => {
					img.onload = resolve;
				});

				const width = img.width;
				const height = img.height;

				let serverImage = await uploadImage(dataURLtoBlob(dataUrl));
				setProgress((i / validImages.length) * 100);
				if (serverImage.hasOwnProperty("data")) {
					let stage = imageToStage(
						(serverImage as any).data.id,
						width,
						height
					);

					slideCollection.slides.push({
						name: files[0].name + " " + (i + 1),
						stage,
						imageIds: [(serverImage as any).data.id],
					});
				}
				console.log("serverImage", serverImage);
			}
			await createSlideCollection(slideCollection);
			setProgress(100);
		} catch (error) {
			console.error("ups", error);
		}
	};

	function dataURLtoBlob(dataurl: any) {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	}
	const handleOpen = () => {
		setOpenPreview(true);
	};
	const handleClose = () => {
		setOpenPreview(false);
	};
	const handleSelectNewFile = () => {
		setFiles([]);
		setActiveStep(0);
	};
	return (
		<Grid
			container
			item
			direction="column"
			justifyContent="center"
			alignItems="center"
			style={{
				backgroundColor: theme.palette.primary.main,
				height: "100%",
			}}
		>
			<Grid
				container
				item
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Grid
					item
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
					style={
						activeStep === 0
							? { display: "flex" }
							: { display: "none" }
					}
				>
					<H1>Upload your material using .pdf format.</H1>
					<FileSelect accept=".pdf" onChange={setFiles} />
				</Grid>
				<H1 style={{ display: activeStep !== 0 ? "flex" : "none" }}>
					{t("In progress ")} [{Math.round(progress)}%] <br />
					{t("This may take a while.")}
				</H1>
				<div
					className={"file-select"}
					style={
						activeStep === 1
							? { display: "flex", border: "2px solid #ffac2b" }
							: { display: "none" }
					}
				>
					<div className="drop-zone-button">
						<Lottie
							animationData={extractAnimation}
							loop={true}
							style={{
								maxWidth: "250px",
								display: "flex",
								alignItems: "center",
								marginTop: "-50px",
								marginBottom: "-40px",
							}}
						/>
						<h2 style={{ textAlign: "center" }}>{t("1 / 2")}</h2>
						<span
							style={{
								textAlign: "center",
								display: "block",
								color: "rgba(255, 255, 255, 0.9)",
							}}
						>
							{t("Convert pdf to slides")}
						</span>
					</div>
				</div>
				{/*
				{images.length > 0 &&
					images.every((i) => !i.isGenerating) &&
					activeStep === 1 && (
						<Button
							variant="contained"
							color="secondary"
							onClick={handleImagesToSlideCollection}
						>
							Upload slides
						</Button>
					)} */}
				<div
					className={"file-select"}
					style={
						activeStep === 2
							? { display: "flex", border: "2px solid #ffac2b" }
							: { display: "none" }
					}
				>
					<div className="drop-zone-button">
						<h2 style={{ textAlign: "center" }}>{t("2 / 2")}</h2>
						<span
							style={{
								textAlign: "center",
								display: "block",
								color: "rgba(255, 255, 255, 0.9)",
							}}
						>
							{t("Upload slides to classuru")}
							{createSlideCollectionError && (
								<h2 style={{ color: "red" }}>
									{t(
										"Name seems to be taken, for now please change the name of the pdf file you upload"
									)}
								</h2>
							)}
							{progress === 100 && (
								<div>
									<h2>It's Done!</h2>
									<Button
										color="secondary"
										variant="contained"
										onClick={() => navigate("/timetable")}
									>
										{t("Create your first Lesson")}
									</Button>
								</div>
							)}
						</span>
					</div>
				</div>
			</Grid>

			<Grid item container direction="row" justifyContent="center">
				<Fade
					in={
						(files.length !== 0 || isGenerating) &&
						images.length === 0 &&
						images.every((i) => i.isGenerating)
					}
				>
					<Button
						color="secondary"
						variant="contained"
						disabled={isGenerating}
						onClick={handleGenerateSlideCollection}
					>
						<Fa
							icon={["fal", "file-dashed-line"]}
							style={{ marginRight: "8px" }}
							fixedWidth
						/>
						{t("Convert into Slides")}
						{isGenerating && <CircularProgress />}
					</Button>
				</Fade>
				{/* {isGenerating && <p>Extracting images from pdf...</p>}*/}
			</Grid>
			<Dialog
				open={openPreview}
				onClose={handleClose}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					Preview Slides
				</DialogTitle>
				<DialogContent dividers>
					{images.map((image, idx) => (
						<Grid item xs={3}>
							<img
								key={idx}
								style={{ width: "100%" }}
								src={
									image.src ||
									"https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
								}
								alt=""
							/>
						</Grid>
					))}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
					<Button onClick={handleClose}>Upload Slides</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

export default SlideCollectionFromPdf;
