import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { forwardRef } from "react";

const ToolButton = styled(Button)(() => ({
	width: "30px !important",
	minWidth: "30px !important",
	padding: "6px",
	height: "30px",
	minHeight: "30px",
	borderRadius: "50%",
}));

type Props = ButtonProps & { active?: boolean };

const MyToolButton = forwardRef(({ active, ...props }: Props, ref: any) => {
	const myStyle = active
		? { backgroundColor: "#ffffff", color: "#2b2240", ...props.style }
		: { ...props.style };
	return <ToolButton ref={ref} {...props} style={myStyle} />;
});

export default MyToolButton;
