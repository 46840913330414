import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
	useCreateSlideCollectionMutation,
	useUploadImageMutation,
} from "rtk/api";
import { useAppDispatch } from "store/hooks";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PageContainer } from "components/pages/PageContainer";
import { Paper } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { CardActionArea } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";

import Lottie from "lottie-react";
import { useAppSelector } from "store/hooks";
import eventAnimation from "assets/lottie/event-animation.json";
import { theme } from "theme";

import { useGetStudentsQuery } from "rtk/api";
import { Student } from "rtk/types";

const Students = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const session = useAppSelector((state) => state.session);
	const { data: students } = useGetStudentsQuery(null);

	return (
		<PageContainer maxWidth="lg">
			<Grid container direction="column">
				<Typography variant="h1" mb={2}>
					{t("Students")}
				</Typography>
				<Grid container direction="row" spacing={2}>
					{students?.map((student: Student) => (
						<Grid item md={3}>
							<Card>
								<CardActionArea
									href={`/students/${student.id}`}
								>
									<CardContent
										style={{
											textAlign: "center",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
										}}
									>
										<Avatar
											style={{
												width: 70,
												height: 70,
												marginTop: theme.spacing(1.5),
											}}
										>
											JB
										</Avatar>
										<Typography mt={2} variant="h2">
											<strong>
												{student.firstName}{" "}
												{student.lastName}
											</strong>
										</Typography>
									</CardContent>
								</CardActionArea>
								<CardActions
									style={{
										display: "flex",
										justifyContent: "space-between",
										borderTop:
											"1px solid rgba(0, 0, 0, 0.12)",
									}}
								>
									<Button size="small" href="/classroom">
										Create Class
									</Button>
									<Button size="small">Send Message</Button>
								</CardActions>
							</Card>
						</Grid>
					))}
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Students;
