import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';

export type ChatMessage = {
  createdAt: Date,
  text: String,
  roomId: String,
  author: String
}

enum ConnectionState {
  Connecting,
  Open,
  Closing,
  Closed,
  Uninstantiated
}

export type ChatState = {
  connectionState: ConnectionState,
  messages: ChatMessage[]
}

const initialState: ChatState = {
  messages: [],
  connectionState: ConnectionState.Uninstantiated
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    send: (state, action) => {
      console.log(state, action)
    }
  },
  extraReducers: () => {
  },
});


export const selectChat = (state: RootState) => state.chat;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default chatSlice.reducer;
