import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { PageContainer } from "components/pages/PageContainer";
import { useTranslation } from "react-i18next";
import { selectSession, UserRole } from "store/reducers/session";
import { useAppSelector } from "store/hooks";
import { setNavbarState } from "store/reducers/app/reducer";

import Card from "@mui/material/Card";

import { UpcommingLessons } from "./UpcommingLessons";

const StyledCard = styled(Card)(({ theme }) => ({
	padding: `${theme.spacing(3)}`,
	marginBottom: `${theme.spacing(2)}`,
	borderRadius: `${theme.spacing(2)}`,
}));
const Dashboard: React.FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(setNavbarState("open"));
	}, []);

	const session = useAppSelector(selectSession);

	return (
		<PageContainer maxWidth="lg">
			<Grid container direction="column">
				<Typography variant="h1" mb={5}>
					Guru Board
				</Typography>
				<Grid
					container
					direction="row"
					spacing={2}
					alignItems="start"
					style={{ overflow: "hidden" }}
					item
				>
					<Grid item md={9} style={{ width: "100%" }}>
						<UpcommingLessons />
					</Grid>

					<Grid item md={3}>
						{UserRole[3] === "Student" && (
							<Typography variant="h2" mb={3} mt={0}>
								News
							</Typography>
						)}
						<StyledCard elevation={0} variant="outlined">
							<Grid container direction="column">
								<Typography variant="h5">
									<strong>{t("Start of closed beta")}</strong>
								</Typography>
								<Typography variant="body2">
									<strong>08.11.2023</strong>
								</Typography>
								<Typography variant="body2">
									{t(
										"We are excited to announce that we are now entering the closed beta phase. Please note that some features are not yet implemented or may not work properly at this early stage. We are looking forward to your feedback and wish you a lot of fun with Classuru!"
									)}
								</Typography>
							</Grid>
							{/*<Divider />
								<ListItem button>
									<ListItemText
										primary="Start der geschlossenen Beta"
										secondary="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy ..."
									/>
	</ListItem>*/}
						</StyledCard>
					</Grid>
				</Grid>
			</Grid>
		</PageContainer>
	);
};

export default Dashboard;
