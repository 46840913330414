import { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Fa } from "styled/muiComponents";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import PerfectScrollbar from "react-perfect-scrollbar";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Zoom from "@mui/material/Zoom";
import { useTranslation } from "react-i18next";
import PencilColor from "../constants/pencilColor";
import { selectSession, selectUser } from "store/reducers/session";
import ToolButton from "./ToolButton";
import { Role } from "../../classRoom";

export enum Tool {
  Pen,
  Eraser,
  Pointer,
  StickyNote,
}

enum Mode {
  Toolbar,
  ColorPicker,
}

const SToolBar = styled(Grid)(() => ({
  width: "60px",
  padding: "0px 5px",
}));
const ColorButton = styled(Button)(() => ({
  width: "25px !important",
  minWidth: "25px !important",
  padding: "0px",
  height: "25px",
  minHeight: "25px",
  borderRadius: "50%",
}));

const Toolbar = ({
  tool,
  onToolChange,
  color,
  onReset,
  onZoomIn,
  onZoomOut,
  onZoomReset,
  onModeChange,
}: ToolProps & { color: PencilColor } & ResetProps &
  ZoomProps &
  Pick<ModeProps, "onModeChange">) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const { t } = useTranslation();
  return (
    <SToolBar
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap="5px"
    >
      <Tooltip title={t("Pointer")} placement="left" TransitionComponent={Zoom}>
        <ToolButton
          onClick={() => onToolChange(Tool.Pointer)}
          value="pointer"
          variant="contained"
          disableElevation
          active={tool === Tool.Pointer}
        >
          <Fa icon={["far", "bullseye-pointer"]} fixedWidth size="sm" />
        </ToolButton>
      </Tooltip>
      <Grid item style={{ position: "relative" }}>
        <Tooltip
          title={tool === Tool.Pen ? t("Select color") : t("Draw")}
          placement="left"
          TransitionComponent={Zoom}
          open={openTooltip}
          onClose={() => setOpenTooltip(false)}
        >
          <ToolButton
            onClick={() =>
              tool === Tool.Pen
                ? onModeChange(Mode.ColorPicker)
                : onToolChange(Tool.Pen)
            }
            value="pen"
            variant="contained"
            disableElevation
            active={tool === Tool.Pen}
            onMouseEnter={() => setOpenTooltip(true)}
            onMouseLeave={() => setOpenTooltip(false)}
          >
            <Fa icon={["fas", "fa-pen"] as any} fixedWidth size="sm" />{" "}
            {/* TODO AS ANY */}
          </ToolButton>
        </Tooltip>
        {/* 
        <Fade in={tool === Tool.Pen}>
          <Button
            style={{
              position: "absolute",
              top: "-2px",
              right: "-2px",
              height: "16px",
              width: "16px",
              minHeight: "10px !important",
              minWidth: "10px",
              backgroundColor: "#2b2240",
              color: "#ffffff",
              borderRadius: "50%",
              border: "1px solid #ffffff",
              fontSize: "10px",
              padding: "5px",
            }}
            //onClick={() => setTool([])} // TODO WHAT IS THIS // a: deselect (Sergei)
          >
            <Fa icon={["far", "fa-xmark"] as any} fixedWidth size="sm" />{" "}
            // TODO AS ANY 
          </Button>
        </Fade>
        */}
      </Grid>
      <Tooltip title={t("Erase")} placement="left" TransitionComponent={Zoom}>
        <ToolButton
          onClick={() => onToolChange(Tool.Eraser)}
          value="eraser"
          variant="contained"
          disableElevation
          active={tool === Tool.Eraser}
        >
          <Fa icon={["far", "fa-eraser"] as any} fixedWidth size="sm" />{" "}
          {/* TODO AS ANY */}
        </ToolButton>
      </Tooltip>
      <Tooltip title={t("Write")} placement="left" TransitionComponent={Zoom}>
        <ToolButton
          onClick={() => onToolChange(Tool.StickyNote)}
          value="text"
          variant="contained"
          disableElevation
          active={tool === Tool.StickyNote}
          disabled
        >
          <Fa icon={["far", "fa-text"] as any} fixedWidth size="sm" 
          style={{color: "rgba(255,255,255,0.5)"}}/>{" "}
          {/* TODO AS ANY */}
        </ToolButton>
      </Tooltip>
      <Tooltip title={t("Reset")} placement="left" TransitionComponent={Zoom}>
        <ToolButton onClick={onReset} variant="contained" disableElevation>
          <Fa icon={["fas", "fa-rotate-left"] as any} fixedWidth size="sm" />{" "}
          {/* TODO AS ANY */}
        </ToolButton>
      </Tooltip>
      {/* 
      <Divider
        style={{ borderColor: "rgb(255 255 255 / 25%)", width: "80%" }}
      />
      <Tooltip title={t("Zoom in")} placement="left" TransitionComponent={Zoom}>
        <ToolButton onClick={onZoomIn} variant="contained" disableElevation>
          <Fa icon={["far", "fa-plus"] as any} fixedWidth size="sm" /> {/* TODO AS ANY 
        </ToolButton>
      </Tooltip>
      <Tooltip
        title={t("Zoom out")}
        placement="left"
        TransitionComponent={Zoom}
      >
        <ToolButton onClick={onZoomOut} variant="contained" disableElevation>
          <Fa icon={["far", "fa-minus"] as any} fixedWidth size="sm" />{" "}
          {/* TODO AS ANY 
        </ToolButton>
      </Tooltip>
      <Tooltip
        title={t("Reset zoom")}
        placement="left"
        TransitionComponent={Zoom}
      >
        <ToolButton onClick={onZoomReset} variant="contained" disableElevation>
          <Fa icon={["fas", "fa-rotate-left"] as any} fixedWidth size="sm" />{" "}
          {/* TODO AS ANY 
        </ToolButton>
      </Tooltip>*/}
    </SToolBar>
  );
};

type UserProps = {
  userRole: Role | null; // TODO: andre fix types
};
const ColorPicker = ({
  color,
  onColorChange,
  onModeChange,
  userRole,
}: ColorProps & UserProps & Pick<ModeProps, "onModeChange">) => {
  const [isLocked, setIsLocked] = useState(false);
  const { t } = useTranslation();
  return (
    <SToolBar
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap="5px"
      style={{ marginLeft: "-60px" }}
    >
      {userRole === "teacher" && (
        <Tooltip
          title={isLocked ? t("Unlock") : t("Lock")}
          placement="left"
          TransitionComponent={Zoom}
        >
          <ToolButton
            onClick={() => {
              setIsLocked(!isLocked);
            }}
            variant="contained"
            disableElevation
            style={
              isLocked ? { backgroundColor: "#ffffff", color: "#2b2240" } : {}
            }
          >
            {isLocked ? (
              <Fa
                icon={["fal", "fa-lock-keyhole"] as any}
                fixedWidth
                size="sm"
              /> // TODO AS ANY
            ) : (
              <Fa
                icon={["fal", "fa-lock-keyhole-open"] as any}
                fixedWidth
                //ml={0.5} // TODO What is ml, seems to not exist? - Andre // ml = margin-left - Sergei
              /> // TODO AS ANY
            )}
          </ToolButton>
        </Tooltip>
      )}
      {Object.entries(PencilColor).map(([name, value], index) => (
        <ColorButton
          key={name}
          variant="contained"
          disableElevation
          onClick={() => {
            onColorChange(value as PencilColor);
            if (!isLocked) onModeChange(Mode.Toolbar);
          }}
          style={{
            backgroundColor: value,
            border:
              color === value ? "3px solid rgba(255,255,255, 0.75)" : "none",
            boxSizing: "content-box",
          }}
        ></ColorButton>
      ))}
    </SToolBar>
  );
};

type ToolProps = {
  tool: Tool | null;
  onToolChange: (tool: Tool) => void;
};
type ColorProps = {
  color: PencilColor;
  onColorChange: (color: PencilColor) => void;
};
type ModeProps = {
  mode: Mode;
  onModeChange: (mode: Mode) => void;
};
type ResetProps = {
  onReset: () => void;
};
type ZoomProps = {
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
};
const ToolbarWrapper = ({
  tool,
  studentTool,
  color,
  onToolChange,
  onColorChange,
  userRole,
  ...props
}: ToolProps &
  ColorProps &
  ResetProps &
  ZoomProps &
  UserProps & { studentTool: Tool | null }) => {
  const [mode, setMode] = useState<Mode>(Mode.Toolbar);

  return (
    <>
      <Slide
        direction="right"
        in={userRole === "teacher" && mode === Mode.Toolbar}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Toolbar
            tool={tool}
            onToolChange={onToolChange}
            color={color}
            onModeChange={setMode}
            {...props}
          />
        </div>
      </Slide>
      <Slide
        direction="right"
        in={
          (userRole === "teacher" && mode === Mode.ColorPicker) ||
          (userRole === "student" && studentTool === Tool.Pen)
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <ColorPicker
            color={color}
            onColorChange={onColorChange}
            onModeChange={setMode}
            userRole={userRole}
          />
        </div>
      </Slide>
    </>
  );
};

export default ToolbarWrapper;
